import React from 'react'
import StartupForm from './StartupForm'
import PageTop from '../Assets/pageTop/PageTop'

function StartupPage() {
  return (
   <>
   <PageTop
        pageName="BUSINESS DIAGNOSTIC"
        headingFirstHalf="FILL " 
        headingSecondHalf="OUT FORMS"
        paragraph="As an entrepreneur or investor, you have a lot on your plate. With numerous responsibilities and limited resources. That's where Amicus come in."
        /> 
        <StartupForm/>
        
   </>
  )
}

export default StartupPage
import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState } from "react";
import './DiagnosticForm.css'

function DiagnosticForm() {

    const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 7
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className='Diagnostic-form'>
        <h2>Business Diagnosis Questionnaire</h2>
    <div className='Diagnostic-form-switcher-wrapper'>
        <Carousel responsive={responsive} >

              <div>
                  <button
                        className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(1)}>Operations
                  </button>
              </div>

              <div>
                  <button
                        className={toggleState === 2 ? "tabs active-tabs Diagnostic-form-btn" : "tabs"}
                        onClick={() => toggleTab(2)}>Sales
                  </button>
              </div>

              <div>
                  <button
                        className={toggleState === 3 ? "tabs active-tabs Diagnostic-form-btn" : "tabs"}
                        onClick={() => toggleTab(3)}>Procurement 
                  </button>
              </div>

              <div>
                  <button
                        className={toggleState === 4 ? "tabs active-tabs Diagnostic-form-btn" : "tabs"}
                        onClick={() => toggleTab(4)}>HR
                  </button>
              </div>
              
              <div>
                  <button
                        className={toggleState === 5 ? "tabs active-tabs Diagnostic-form-btn" : "tabs"}
                        onClick={() => toggleTab(5)}>Finance
                  </button>
              </div>

              <div>
                  <button
                        className={toggleState === 6 ? "tabs active-tabs Diagnostic-form-btn" : "tabs"}
                        onClick={() => toggleTab(6)}>Technology
                  </button>
              </div>
              <div>
                  <button
                        className={toggleState === 7 ? "tabs active-tabs Diagnostic-form-btn" : "tabs"}
                        onClick={() => toggleTab(7)}>Contact Form
                  </button>
              </div>
            
          </Carousel>
            </div>

                    <div className={toggleState === 1 ? "  active-content Diagnostic-form-content-wrapper" : "content"}>
                        <div className='Diagnostic-form-content ' >
                            <ol className='Diagnostic-box'>
                                <li >Are you experiencing any specific challenges or issues in your business or organization?</li>
                                <li>Have you identified the root cause of the problem you're facing?</li>
                                <li>Do you have a clear understanding of your business goals and objectives?</li>
                                <li>Have you conducted a thorough analysis of your target market and customer base?</li>
                                <li>Are you satisfied with your current marketing and advertising strategies?</li>
                                <li>Have you implemented any specific measures to improve employee productivity and engagement?</li>
                                <li>Do you have a well-defined organizational structure and clear lines of communication?</li>
                                <li>Have you conducted an assessment of your financial performance and profitability?</li>
                                <li>Are you aware of the latest industry trends and technological advancements relevant to your business?</li>
                                <li>Have you sought professional advice or consultancy services in the past to address similar challenges?</li>
                            </ol> 
                        </div>
                        <div className='Diagnostic-form-tickbox'>
                        <button>ok</button>
                        <button>ok</button>
                        <button>ok</button>
                        <button>ok</button>
                        <button>ok</button>
                        
                        </div>
                    </div> 

                    <div className={toggleState === 2 ? "  active-content Diagnostic-form-content-wrapper" : "content"}>
                        <div className='Diagnostic-form-content ' >
                            <ol className='Diagnostic-box'>
                                <li >Are you experiencing any specific challenges or issues in your business or organization?</li>
                                <li>Have you identified the root cause of the problem you're facing?</li>
                                <li>Do you have a clear understanding of your business goals and objectives?</li>
                                <li>Have you conducted a thorough analysis of your target market and customer base?</li>
                                <li>Are you satisfied with your current marketing and advertising strategies?</li>
                                <li>Have you implemented any specific measures to improve employee productivity and engagement?</li>
                                <li>Do you have a well-defined organizational structure and clear lines of communication?</li>
                                <li>Have you conducted an assessment of your financial performance and profitability?</li>
                                <li>Are you aware of the latest industry trends and technological advancements relevant to your business?</li>
                                <li>Have you sought professional advice or consultancy services in the past to address similar challenges?</li>
                            </ol> 
                        </div>
                        <div className='Diagnostic-form-tickbox'>
                            <button>ok</button>
                            <button>ok</button>
                            <button>ok</button>
                            <button>ok</button>
                            <button>ok</button>
                        </div>
                    </div> 

                    <div className={toggleState === 3 ? "  active-content Diagnostic-form-content-wrapper" : "content"}>
                        <div className='Diagnostic-form-content ' >
                            <ol className='Diagnostic-box'>
                                <li >Are you experiencing any specific challenges or issues in your business or organization?</li>
                                <li>Have you identified the root cause of the problem you're facing?</li>
                                <li>Do you have a clear understanding of your business goals and objectives?</li>
                                <li>Have you conducted a thorough analysis of your target market and customer base?</li>
                                <li>Are you satisfied with your current marketing and advertising strategies?</li>
                                <li>Have you implemented any specific measures to improve employee productivity and engagement?</li>
                                <li>Do you have a well-defined organizational structure and clear lines of communication?</li>
                                <li>Have you conducted an assessment of your financial performance and profitability?</li>
                                <li>Are you aware of the latest industry trends and technological advancements relevant to your business?</li>
                                <li>Have you sought professional advice or consultancy services in the past to address similar challenges?</li>
                            </ol>
                        </div>
                        <div className='Diagnostic-form-tickbox'>
                            <button>ok</button>
                            <button>ok</button>
                            <button>ok</button>
                            <button>ok</button>
                            <button>ok</button>
                        </div>
                    </div> 


        </section>
  )
}

export default DiagnosticForm
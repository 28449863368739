import React from 'react';
import './ServiceRight.scss';
import { Container, Row, Col } from 'react-bootstrap';
import Accordions from '../Assets/Accordion';

function ServiceRight({headingFirstHalf, headingSecondHalf, description, img,  title1, desc1, title2, desc2, title3, desc3, title4, desc4, title5, desc5}) {
  return (
    <div className='service-right'>
    <Container className='service-right-container'>
   <Row className='service-right-row'>
   <Col xs={12} sm={12} md={12} lg={6}> 
     <img src={img} alt="services-images" style={{width:'92%'}}/> 
     </Col >
 
     <Col xs={12} sm={12} md={12} lg={6} className='service-right-content'>  
       <Row>
       <h2 style={{paddingBottom:"15px"}}>{headingFirstHalf}<br/> {headingSecondHalf}</h2>
        <p>{description}</p>
       </Row> 
       <Row>  
       <Accordions 
       pageName="service-page-accordion" 
       t1={title1}
       d1={desc1}
       t2={title2}
       d2={desc2}
       t3={title3}
       d3={desc3}
       t4={title4}
       d4={desc4}
       t5={title5}
       d5={desc5}
       />
       </Row>
     
     </Col>
     
   </Row>
 </Container>
</div>
  )
}

export default ServiceRight
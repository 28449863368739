import React from 'react';
import './Slider.scss';
import AllButton from './AllButton';


function BottomCTA() {
  return (
    <div className='BottomCTA'>
    {/* <div className='BottomCTA' style={{backgroundImage: 'url("./GrowBannerHD4.jpg")' , backgroundSize: "cover", backgroundRepeat: "no-repeat"}}> */}
            {/* <img src='./GrowBannerHD4.jpg' alt="bgbottom"/> */}
             
            <p><strong>Nature</strong> is not a place to visit, <br/><strong>It is home</strong></p>
            <div className='BottomCTA-btn'>
            <a href="https://www.grow-trees.com/plant/greet-new-desktop.php?promotions=AMICUS2024&MP_ID=91&MC_ID=571" target="__blank" ><AllButton cta="Grow green, breathe clean"/></a>
            {/* <a href="https://www.grow-trees.com/plant/greet-new-desktop.php?MP_ID=91" target="blank"><button>Grow green, breathe clean</button></a> */}
            
            </div>
            
       
    </div>
  )
}

export default BottomCTA
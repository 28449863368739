import React from 'react';
import './BlogSingle.scss';
import PageTop from '../Assets/pageTop/PageTop';
import AboutBanner from '../AboutPage/AboutBanner';

function BlogSingle() {
  return (
    <div>
        
    <PageTop 
        pageName="BLOG"
        headingFirstHalf="READ "
        headingSecondHalf="COMPLETE ARTICLE"
        paragraph="As an entrepreneur or investor, you have a lot on your plate. With numerous responsibilities and limited resources. That's where Amicus come in."
        />

<div className='blogSingleOne'>
        <div className='blogsingle_content_area'>
            <div className='blogsingle_content_wrapper'>
                <h4 style={{alignText:'start',color:'#717070',fontSize:'1.5rem'}}>BLOG</h4>
                <h1>Why Conduct A Marketing Audit For Your <span>Small Business</span></h1>
                <h4 style={{alignText:'start',fontWeight:'400', fontSize:'1.1rem'}}>BUSINESS CONSULTING</h4>
                <p style={{fontSize:'1.1rem',fontWeight:'400'}}>Running a successful small business requires effective marketing strategies to attract customers, increase brand awareness, and drive growth. However, as the business landscape evolves. </p>
            </div>
        </div>

<AboutBanner 
image='./About-banner.png'
quote='We will Work In A Perpetual State Of Discovery To
Craft Customer Experiences That Have A
Meaningful, Measurable Impact.'
/>
        {/* <div className='blogsingle_image' >
            <img src='./Imgs/uiux.jpg' alt='blogsingle_image'/>
        </div>  */}

<div className='blogsingle_content_one'>
        <p style={{fontSize:'1.3rem',fontWeight:'400',lineHeight:'1.6rem'}}><b>Gain a Clear Understanding of Your Current Marketing Efforts:</b></p>
        <br/><br/>
            <ul style={{fontSize:'1.2rem'}}>
                <li>A marketing audit provides a comprehensive assessment of your existing marketing strategies, tactics, and channels. It helps you gain a clear understanding of what's working well and what needs improvement.</li>
                <br/>
                <li>By examining your marketing materials, campaigns, and online presence, you can identify areas that may require adjustment or realignment with your business goals. </li>
                <br/>
                <li>This process allows you to make data-driven decisions based on a thorough analysis of your marketing activities.</li>
                <br/>
            </ul>

            <p style={{fontSize:'1.3rem',fontWeight:'400',lineHeight:'1.6rem'}}><b>Identify Emerging Opportunities and Trends:</b></p>
        <br/><br/>
            <ul style={{fontSize:'1.2rem'}}>
                <li>The business landscape is constantly evolving, with new technologies, platforms, and marketing trends emerging regularly. </li>               
                <br/>
                <li>A marketing audit keeps you informed about the latest developments in your industry and allows you to identify emerging opportunities. By staying ahead of the curve</li>
                <br/>
                <li>you can explore new channels, engage with your audience in innovative ways, and leverage trends to gain a competitive edge. Regular marketing audits ensure that your small business remains adaptable, agile, and well-positioned to seize new opportunities.</li>
                <br/>
            </ul>


</div>
<div className='blogsingle_quote_container'>
    <div className='blogsingle_quote_wrapper'>
      <h2>“Help your team feel more connected to your company by incorporating the right digital technology tools into their workflow.”
</h2>
    </div>
    
</div>



<div className='blogsingle_content_two' style={{fontSize:'1.1rem',fontWeight:'400'}}>
<p>
    <h2 style={{fontSize:'1.9rem', lineHeight:'2.3rem'}}>Assess Marketing ROI and Budget Allocation:</h2>
    <br/>
    <p>Marketing efforts require investment, both in terms of time and financial resources. A marketing audit helps you evaluate the return on investment (ROI) of your marketing activities.</p>
    <br/>
<ul>
  <li>By analyzing key performance indicators (KPIs) such as conversion rates, customer acquisition costs, and revenue generated, you can assess the effectiveness of your marketing campaigns.</li>
  <br/>
  <li>Manufacturing: AI may be used to improve efficiency and productivity in manufacturing by automating tasks and enabling real-time analysis of data from production processes.</li><br/>
  <li>A marketing audit keeps you informed about the latest developments in your industry and allows you to identify emerging opportunities.</li>
</ul>
</p>
</div>

 
    </div>

    </div>
  )
}

export default BlogSingle
import React from 'react';
import './IndustryHome.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState } from "react";


function IndustryHome() {

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return ( 
    <div className='industry-home'>
      <div className='industry-home-switcher-container'>
          <h2>OUR INDUSTRIES</h2>
          
          <div className='industry-home-switcher-wrapper'>
          <Carousel responsive={responsive} >

              <div>
                  <button
                        className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(1)}>Logistics
                  </button>
              </div>

              <div>
                  <button
                        className={toggleState === 2 ? "tabs active-tabs industry-home-btn" : "tabs"}
                        onClick={() => toggleTab(2)}>Ecommerce
                  </button>
              </div>

              <div>
                  <button
                        className={toggleState === 3 ? "tabs active-tabs industry-home-btn" : "tabs"}
                        onClick={() => toggleTab(3)}>Retail 
                  </button>
              </div>

              <div>
                  <button
                        className={toggleState === 4 ? "tabs active-tabs industry-home-btn" : "tabs"}
                        onClick={() => toggleTab(4)}>Manufacturing
                  </button>
              </div>
              
              <div>
                  <button
                        className={toggleState === 5 ? "tabs active-tabs industry-home-btn" : "tabs"}
                        onClick={() => toggleTab(5)}>Hospitality
                  </button>
              </div>

              <div>
                  <button
                        className={toggleState === 6 ? "tabs active-tabs industry-home-btn" : "tabs"}
                        onClick={() => toggleTab(6)}>EdTech
                  </button>
              </div>
            
          </Carousel>
            </div>
            
          
            
          
      </div>
      
        {/* <div className='industry-home-content-wrapper'> */}
        <div className={toggleState === 1 ? "  active-content industry-home-content-wrapper" : "content"}>
            <div className='industry-home-content-img'>
              <img src='./images/logistics.jpg' alt='Industries-images'/>
            </div>
            <div className='industry-home-content ' >
            <h2 style={{fontSize:"2.5rem", fontWeight:"500", color: "white"}}>Logistics</h2>
            <p>In the rapidly evolving logistics industry, operational efficiency, speed, and customer satisfaction are pivotal. At our firm, we comprehend the unique challenges and opportunities within this sector. Our comprehensive suite of services empowers businesses to navigate intricate supply chain networks, streamline operations, and leverage technology for growth. </p>
            <p>From transforming business models to optimizing financial structures, we assist in charting a course towards sustainable success. Partner with us to stay ahead in this dynamic industry and turn logistical challenges into competitive advantages.</p>
            </div>
        </div> 

        <div className={toggleState === 2 ? "active-content industry-home-content-wrapper" : "content"}>
            <div className='industry-home-content-img'>
              <img src='./images/ecom.jpg' alt='Industries-images'/>
            </div>
            <div className='industry-home-content ' >
            <h2 style={{fontSize:"2.5rem", fontWeight:"500", color: "white"}}>Ecommerce</h2>
            <p>Ecommerce business models are reshaping the landscape of commerce, prioritizing direct engagement and personalized experiences. Our consultancy firm is adept at navigating the intricacies of the D2C sector. We offer an array of services, from crafting effective business transformation strategies to providing financial guidance, aimed at augmenting your D2C venture.</p>
            <p>We help you foster strong customer relationships, create impactful digital touchpoints, and build efficient supply chains. Partner with us to spearhead your growth in the D2C industry, transforming challenges into opportunities for success.</p>
            </div>
        </div>

        <div className={toggleState === 3 ? "active-content industry-home-content-wrapper" : "content"}>
            <div className='industry-home-content-img'>
              <img src='./images/retail.jpg' alt='Industries-images'/>
            </div>
            <div className='industry-home-content ' >
            <h2 style={{fontSize:"2.5rem", fontWeight:"500", color: "white"}}>Retail</h2>
            <p>In the vibrant retail sector, customer preferences and technology are constantly evolving. Our services focus on driving growth through innovative strategies and digital transformation. </p>
            <p>We help retailers optimize their operations, build stronger customer relationships, and create effective omni-channel experiences. Embrace the future of retail with us, guiding your business towards resilience and profitability in the face of rapidly changing market dynamics.</p>
            </div>
        </div>

        <div className={toggleState === 4 ? "active-content industry-home-content-wrapper" : "content"}>
            <div className='industry-home-content-img'>
              <img src='./images/manufacturing.jpg' alt='Industries-images'/>
            </div>
            <div className='industry-home-content ' >
            <h2 style={{fontSize:"2.5rem", fontWeight:"500", color: "white"}}>Manufacturing</h2>
            <p>The manufacturing industry, a cornerstone of economic development, faces unique challenges in operational efficiency, innovation, and sustainability. Our services equip manufacturers with strategies for business transformation, optimized financial management, and effective M&A planning.</p>
            <p>Rely on us to strengthen your competitive edge, assisting your manufacturing enterprise to thrive in a technologically advanced and globally connected landscape.</p>
            </div>
        </div>

       

        <div className={toggleState === 5 ? "active-content industry-home-content-wrapper" : "content"}>
            <div className='industry-home-content-img'>
              <img src='./images/hospitality.jpg' alt='Industries-images'/>
            </div>
            <div className='industry-home-content ' >
            <h2 style={{fontSize:"2.5rem", fontWeight:"500", color: "white"}}>Hospitality</h2>
            <p>The hospitality sector thrives on exceptional guest experiences and efficient operations. We provide comprehensive consultancy services, aiding in business transformation, financial optimization, and strategic planning.</p>
            <p>With our expert guidance, enhance your hospitality enterprise's guest satisfaction, operational excellence, and financial performance, ensuring you stay ahead in a rapidly evolving industry landscape.</p>
            </div>
        </div>

        <div className={toggleState === 6 ? "active-content industry-home-content-wrapper" : "content"}>
            <div className='industry-home-content-img'>
              <img src='./images/edtech.jpg' alt='Industries-images'/>
            </div>
            <div className='industry-home-content ' >
            <h2 style={{fontSize:"2.5rem", fontWeight:"500", color: "white"}}>Edtech</h2>
            <p>As technology revolutionizes education, the EdTech sector is growing at an unprecedented rate. Our expert services assist EdTech enterprises in strategic planning, financial management, and business transformation.</p>
            <p>With us, navigate the complexities of this emerging market smoothly, enabling your EdTech venture to harness technology's potential and lead the wave of educational innovation and success.</p>
            </div>
        </div>
        
        
        <div></div>
    </div>
  )
}

export default IndustryHome
import React from 'react'
import PageTop from '../Assets/pageTop/PageTop'
import IndustryTop from './IndustryTop'
import IndustryExpertise from './IndustryExpertise'
import { Helmet } from 'react-helmet'

function Edtech() {
  return (
    <div>
      <Helmet>
            <meta charSet="utf-8" />
            <title>Business Consultant | Business Advisor - Amicus Growth Advisors</title>
            <meta name="keyword" content="business consultant, business consultant in india, business advisor, strategy consultant, go to market strategist" />
            <meta name="description" content="Amicus Growth Advisors, India's leading business consultancy, specializes in strategy, market entry, and expert advising to propel your business forward." />
            <link rel="canonical" href="https://www.amicusllp.com/edtech" />
        </Helmet> 

         <PageTop
        pageName="INDUSTRY PAGE" 
        headingFirstHalf="DIVERSE "
        headingSecondHalf="SECTORS"
        belowHeading='Embrace the dynamism of industries; fuel your enterprise potential.'
        paragraph="As a business leader in a dynamic industry, challenges are part and parcel of your journey. With a multitude of tasks and scarce resources, it's crucial to stay agile. Our firm is here to guide you through industry-specific complexities, help unearth hidden opportunities, offer a fresh industry perspective, and equip you for the next big industry breakthrough."
        />
     <IndustryTop  
     topHeading = 'EdTech'
     topPara1 = 'As technology revolutionizes education, the EdTech sector is growing at an unprecedented rate. Our expert services assist EdTech enterprises in strategic planning, financial management, and business transformation.'
     topPara2 = "With us, navigate the complexities of this emerging market smoothly, enabling your EdTech venture to harness technology's potential and lead the wave of educational innovation and success."
     img = './images/edtech.jpg'
     /> 
    <IndustryExpertise 
      h1='Business Transformation'
      desc1='Edtech'
      h2='Board Advisory'
      desc2='Utilize our expert guidance to craft insightful roadmaps and effective corporate governance strategies.'
      h3='Virtual CFO Service'
      desc3='Optimize financial performance with our comprehensive, remote CFO services, tailored to your needs.'
      h4='Mergers and acquisitions'
      desc4='Seamlessly navigate M&As with our strategies, maximizing value and ensuring smooth transitions.'
      h5='Debt & Equity Funding'
      desc5=' Leverage our expertise for optimal funding solutions, striking a balance between debt and equity.'
      
      />
    </div>
  )
}

export default Edtech
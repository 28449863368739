import React from 'react'
import {useParams} from "react-router-dom"
import { useState, useEffect, useRef } from 'react';
import { client } from '../../client';
import BlockContent from "@sanity/block-content-to-react"
import './ReportSingle.scss'
import ReportRelated from './ReportRelated';
import Form from 'react-bootstrap/Form';
import { Button, Row, Col } from 'react-bootstrap';
import {Helmet} from "react-helmet";


function SinglePost() {
  
  const formRef = useRef(null)
  const scriptUrl = "https://script.google.com/macros/s/AKfycbybWTW3ck1l2DrlL-EBcDXmIWLyEh74HbhB8Vzbrz6GI8x1IV1dHEzZqfWsdt_zH0BKWg/exec"
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) =>{
    console.log("clicked")
      e.preventDefault()
      setLoading(true)
      console.log(formRef.current);

      fetch(scriptUrl, {
      method: 'POST', 
      body: new FormData(formRef.current),
      

  }).then(res => {
          // alert("SUCCESSFULLY SUBMITTED") {`${singlePost.link}`}
          // window.location.href = 'https://drive.google.com/file/d/1gov-govA-YWIYxobfvS1K4FoetOWPVdK/view'
          window.location.href = `${singlePost.link}`
          setLoading(false)
      })
      .catch(err => console.log(err))
  }



const pdfRef = useRef();
// const downloadPDF = () => {
//   const input = pdfRef.current;
//   html2canvas(input).then((canvas) => {
//     const imgData = canvas.toDataURL('image/png');
//     const pdf = new jsPDF('p', 'mm', 'a4', true);
//     const pdfWidth = pdf.internal.pageSize.getWidth();
//     const pdfHeight = pdf.internal.pageSize.getHeight();
//     const imgWidth = canvas.width;
//     const imgHeight = canvas.height;
//     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
//     const imgX = (pdfWidth - imgWidth * ratio) / 2;
//     const imgY = 30;
//     pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio );
//     // pdf.addImage(imgData, 'JPEG', 0, 0);
//     pdf.save('Amicus-Report.pdf');

//   })
// }


  const[singlePost, setSinglePost] = useState([])
  const[isLoading, setIsLoading] = useState(true)
  const {slug} = useParams()

  useEffect(() => {
    client.fetch(
      `*[slug.current == "${slug}"] {
        title,
        body,
        link,
        mTitle,
        mKeyword,
        mDesc,
        mLink,
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        }
      }`
    ).then((data) => setSinglePost(data[0]))
    setIsLoading(false)
  }, [slug])

  return (
    <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>{singlePost.mTitle}</title>
            <meta name="keyword" content={singlePost.mKeyword} />
            <meta name="description" content={singlePost.mDesc}  />
            <link rel="canonical" href={`${singlePost.mLink}`} />
        </Helmet>


    <div className='singlePost' ref={pdfRef}>
  
      {isLoading ? <h1>Loading...</h1>:
      <section className='singlePost-wrapper' >
        {/* <div className='singlePost-heading'>
          <h1>{singlePost.title}</h1>
        </div> */}
        
        {singlePost.mainImage && singlePost.mainImage.asset &&(
          <img src={singlePost.mainImage.asset.url} alt={singlePost.title} title={singlePost.title}/>
        )}

        <div className='singlePost-heading'>
          <h1>{singlePost.title}</h1>
        </div>
        {/* <p>By Samyak</p>  */}
        <div className='singlePost-mainContent'>
          <BlockContent
          blocks={singlePost.body}
          projectId="7p0mupoj"
          dataset="production" 
          />

<div className='contactform-report'>
<div className='contactform-wrapper'>
       <h4>Download Complete Report</h4>
       <Form className='contact-form' ref={formRef} onSubmit={handleSubmit} name="google-sheet">
        <Row> 
           <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
           <Form.Control type="email" name="Email" placeholder="E-mail Address" />
           </Form.Group>
        </Row>

        <Row>
        <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
          <Form.Control type="text" name="Number" placeholder="Contact Number" required/>
        </Form.Group>
        </Row>
        
     <Button variant="outline-secondary" type="submit" className="submit-btn btn-block"  >VIEW REPORT</Button>

      </Form>
    </div>
    </div>

         
          {/* <button className='download-btn'>
            <a href={`${singlePost.link}`} target='_blank' style={{color:'white'}}>
              View Report</a></button> */}
        </div>
         
        
          
        {/* <Link to='/blog'>
        <button style={{backgroundColor:'transparent',border:'none',color:'#566dc8' }}>
          Read more articles
        </button>
        </Link> */}
      </section>
      }
    </div>

   <ReportRelated/>

    </>
  )
}

export default SinglePost
import React from 'react';
import './IndustryExpertise.scss'

function IndustryExpertise({desc1,desc2,desc3, desc4,desc5,h1,h2,h3,h4,h5}) {
  return (
    <div  className='IndustryExpertise'>
      <h2>OUR EXPERTISE</h2>
      <div className='IndustryExpertise-wrapper'>
        <div className='IndustryExpertise-card'>
          <h3 style={{width:'50%',paddingBottom:'10px'}}>{h1}</h3>
          <p>Propel your <b>{desc1}</b> business with strategic change management for sustainable growth and competitiveness</p> 
        </div>

        <div className='IndustryExpertise-card'>
        <h3 style={{width:'50%',paddingBottom:'10px'}}>{h2}</h3>
          <p>{desc2}</p>
        </div>

        <div className='IndustryExpertise-card'>
        <h3 style={{width:'60%',paddingBottom:'10px'}}>{h3}</h3>
          <p>{desc3}</p>
        </div>
      </div>

      <div className='IndustryExpertise-wrapper'>
        <div className='IndustryExpertise-card'>
        <h3 style={{width:'50%',paddingBottom:'10px'}}>{h4}</h3>
          <p>{desc4}</p>       
       </div>

        <div className='IndustryExpertise-card'>
        <h3 style={{width:'75%',paddingBottom:'10px'}}>{h5}</h3>
          <p>{desc5}</p>
        </div>

        
      </div>

    </div>
  )
} 

export default IndustryExpertise
import React from 'react';
import './ServiceOffering.scss';
import { Link } from 'react-router-dom';

function ServiceOffering(props) {
  return ( 
    <div className='ServiceOffering'>
      <div className='ServiceOffering-top'>
        <h2>{props.heading}</h2>
        <p>Top priorities and obstacles to Success</p>
          <div class="button" id="button-5">
            <div id="translate"></div>
                <Link to="/contact">
                <a style={{color:'white'}}>Get Free Expert Assistance</a>
                </Link>
            </div>
       
      </div>

      <div className='ServiceOffering-bottom'>
          <div className='ServiceOffering-bottom-box1'>
            <h4>{props.s1}</h4>
          </div>
          <div className='ServiceOffering-bottom-box2'>
            <h4>{props.s2}</h4>
          </div>
          <div className='ServiceOffering-bottom-box3'>
            <h4>{props.s3}</h4>
          </div>
          <div className='ServiceOffering-bottom-box4'>
            <h4>{props.s4}</h4>
          </div>
      </div>
    </div>
  )
}

export default ServiceOffering
import React from 'react';
import './HomeServices.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import ServiceCardNew from './ServiceCardNew';
import ServiceCard from './ServiceCard';


function HomeServices() {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

  return (
    <>
    <div className='home-service'>
        <div className='home-service-container container'>
          <div className='home-service-content'>
              <div className='home-service-content-box-left'>
              <h2>OUR EXPERTISE</h2>
              <h3>A <span style={{fontWeight:'strong'}}>delightful Services</span> to meet your Business needs</h3>
              </div>

              <div className='home-service-content-box-right'>

              </div>
            
          </div>
            
    <div className='container'>
        <Carousel responsive={responsive} 
         nextIcon = "Next"
         prevIcon = "prev"
         indicators = {false}
        >
            <div className='home-service-item'> 
            <ServiceCard 
            heading1='Business' 
            heading2='Transformation'
            oneliner='Your Roadmap for IPO'
            desc='Shaping your business for exponential and sustainable growth'
            link='/services/business-transformation-consultants'
            />
            </div> 
            <div className='home-service-item'> 
            <ServiceCard
            heading1='SME IPO'
            heading2='Advisory'
            oneliner='Fueling Business Growth'
            desc='Empowering Small Businesses to Shine Bright on the IPO Stage.'
            link='/services-sme-ipo-consultants'
            />
            </div>
            <div className='home-service-item'> 
            <ServiceCard
            heading1='Business'
            heading2='Diagnostic'
            oneliner='Discover Your Business'
            desc='Decoding business health with in-depth, strategic analyses'
            link='/services/business-diagnostics-and-strategic-business-consultant'
            />
            </div>
            
            <div className='home-service-item'> 
            <ServiceCard
            heading1='Startup' 
            heading2='Advisory'
            oneliner='Igniting Startups, Fueling Futures'
            desc='Tailored strategies to propel your startup success'
            link='/services/startup-consulting-and-advisory-services'
            />
            </div>
            <div className='home-service-item'> 
            <ServiceCard
            heading1='Board '
            heading2='Advisory'
            oneliner='Guiding Boards, Shaping Excellence'
            desc='High-level guidance for effective board governance'
            link='/services/board-advisory'
            />
            </div>
            <div className='home-service-item'> 
            <ServiceCard
            heading1='Virtual-CFO'
            heading2='Services'
            oneliner='Beyond Numbers, Strategic Financial Partners'
            desc='Strategic financial insights at a fraction of cost'
            link='/services/virtual-cfo'
            />
            </div>
          
            <div className='home-service-item'> 
            <ServiceCard
            heading1='Merger'
            heading2='& Acquisitions'
            oneliner='Unveiling Synergies, Unleashing Growth'
            desc='Navigating complex transactions'
            link='/services/merger-acquisitions'
            />
            </div>
            <div className='home-service-item'> 
            <ServiceCard
            heading1='ESG'
            heading2='Advisory'
            oneliner='Ethics Elevated, Impact Amplified'
            desc='Turn around your business with Transformation Advisory'
            link='/services/esg-advisory'
            />
            </div>
            
        </Carousel>
        </div>
        </div>
    </div>
    </>
  )
}

export default HomeServices
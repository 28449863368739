import React from 'react';
import './TeamHome.css';
import { Link } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TeamCard from './TeamCard';

function TeamHome() {
  return (
    <>
    
        <div className='team-home-img'>
          <TeamCard 
          name='SANJEEV JAIN'
          work='FOUNDING PARTNER'
          image='./sk_jain.png' 
          linkedin ='https://www.linkedin.com/in/sanjeev-jain-b7636314/'
          /> 
          <TeamCard 
          name='VIKAS PAWAR'
          work='PARTNER'
          image='./vikas.png'
          linkedin ='https://www.linkedin.com/in/vikasvpawar/'
          />
          <TeamCard 
          name='PULKIT VIJAY'
          work='CONSULTANT'
          image='./pulkit.png' 
          linkedin ='https://in.linkedin.com/in/pulkit-vijay'
          />

        </div> 
    
    </>
  )
}

export default TeamHome
import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {useRef, useState} from 'react';
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import './StartupForm.css'

function StartupForm() {

  const formRef = useRef(null)
  const scriptUrl = "https://script.google.com/macros/s/AKfycbyspKoLkoQAMo79xJeZnZrdcgkl56K9GfmR4oXB_1yI6xZQit0doXY9PPu6D9bm7RM/exec"
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) =>{
    console.log("clicked")
      e.preventDefault()
      setLoading(true)
      console.log(formRef.current);

      fetch(scriptUrl, {
      method: 'POST', 
      body: new FormData(formRef.current),
      

  }).then(res => {
          alert("SUCCESSFULLY SUBMITTED")
          setLoading(false)
      })
      .catch(err => console.log(err))
  }


// TAB SECTION STARTS HERE
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 9
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className='Diagnostic-form'>
        <h2>Startup Funding Form</h2>
    <div className='Diagnostic-form-switcher-wrapper'>
        <Carousel responsive={responsive} >

              <div>
                  <button
                        className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
                        onClick={() => toggleTab(1)}>About Startup
                  </button>
              </div>

              <div>
                  <button
                        className={toggleState === 2 ? "tabs active-tabs Diagnostic-form-btn" : "tabs"}
                        onClick={() => toggleTab(2)}>Problem Statements
                  </button>
              </div>

              <div>
                  <button
                        className={toggleState === 3 ? "tabs active-tabs Diagnostic-form-btn" : "tabs"}
                        onClick={() => toggleTab(3)}>Customer Segment 
                  </button>
              </div>

              <div>
                  <button
                        className={toggleState === 4 ? "tabs active-tabs Diagnostic-form-btn" : "tabs"}
                        onClick={() => toggleTab(4)}>Product/Services & USP
                  </button>
              </div>
              
              <div>
                  <button
                        className={toggleState === 5 ? "tabs active-tabs Diagnostic-form-btn" : "tabs"}
                        onClick={() => toggleTab(5)}>Market
                  </button>
              </div>

              <div>
                  <button
                        className={toggleState === 6 ? "tabs active-tabs Diagnostic-form-btn" : "tabs"}
                        onClick={() => toggleTab(6)}>GTM, Cost Structure, Pricing
                  </button>
              </div>
              <div>
                  <button
                        className={toggleState === 7 ? "tabs active-tabs Diagnostic-form-btn" : "tabs"}
                        onClick={() => toggleTab(7)}>Status of work
                  </button>
              </div>
              <div>
                  <button
                        className={toggleState === 8 ? "tabs active-tabs Diagnostic-form-btn" : "tabs"}
                        onClick={() => toggleTab(7)}>Roadmap & Funding
                  </button>
              </div>
              <div>
                  <button
                        className={toggleState === 9 ? "tabs active-tabs Diagnostic-form-btn" : "tabs"}
                        onClick={() => toggleTab(7)}>Aplicants' Detail
                  </button>
              </div>
            
          </Carousel>
        </div>


            <Form className='contact-form' ref={formRef} onSubmit={handleSubmit} name="google-sheet">
                  <div className={toggleState === 1 ? "  active-content Diagnostic-form-content-wrapper" : "content"}>
                    <div className='Diagnostic-form-content' >
                      <div className='contactform-wrapper'>
                         <h2>About Startup</h2>
                             
                                  <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
                                    <Form.Control type="text" name="AS1" placeholder="Problem addressed" />
                                  </Form.Group>
                                  <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
                                    <Form.Control type="text" name="AS2" placeholder="What is the problem/ pain point you are trying to solve?" />
                                  </Form.Group>
                                  <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
                                    <Form.Control type="text" name="AS3" placeholder="What is your proposed solution to address the problem?" />
                                  </Form.Group>
                                  <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
                                    <Form.Control type="text" name="AS4" placeholder="How, in layman terms, does your solution work and help in solving the problem ?" />
                                  </Form.Group>
                                
                                   
                           
                      </div>
                    </div>
                        
                    </div> 

                    <div className={toggleState === 2 ? "  active-content Diagnostic-form-content-wrapper" : "content"}>
                        <div className='Diagnostic-form-content ' >
                            <div className='contactform-wrapper'>
                            <h2>Problem Statements</h2>
                                
                                      <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
                                        <Form.Control type="text" name="PS1" placeholder="Problem addressed" />
                                      </Form.Group>
                                      <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
                                        <Form.Control type="text" name="PS2" placeholder="What is the problem/ pain point you are trying to solve?" />
                                      </Form.Group>
                                      <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
                                        <Form.Control type="text" name="PS3" placeholder="What is your proposed solution to address the problem?" />
                                      </Form.Group>
                                      <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
                                        <Form.Control type="text" name="PS4" placeholder="How, in layman terms, does your solution work and help in solving the problem ?" />
                                      </Form.Group>
                                    
                                
                            </div>
                        </div>
                    </div> 

                    <div className={toggleState === 3 ? "  active-content Diagnostic-form-content-wrapper" : "content"}>
                        <div className='Diagnostic-form-content'>
                        <div className='contactform-wrapper'>
                            <h2>Customer Segment</h2>
                                {/* <Form className='contact-form' ref={formRef} onSubmit={handleSubmit} name="google-sheet"> */}
                                      <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
                                        <Form.Control type="text" name="CS1" placeholder="Problem addressed" />
                                      </Form.Group>
                                      <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
                                        <Form.Control type="text" name="CS2" placeholder="What is the problem/ pain point you are trying to solve?" />
                                      </Form.Group>
                                      <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
                                        <Form.Control type="text" name="CS3" placeholder="What is your proposed solution to address the problem?" />
                                      </Form.Group>
                                      <Form.Group className="mb-3" controlId="contactForm.ControlInput1">
                                        <Form.Control type="text" name="CS4" placeholder="How, in layman terms, does your solution work and help in solving the problem ?" />
                                      </Form.Group>

                                      <Form.Check 
                                          className='pt-2 pb-4'
                                          type={'checkbox'}
                                          id={'check'}
                                          label={'By clicking Send, I agree to the terms & privacy policy.'}
                                        />
    
                                      <Button variant="outline-secondary" type='submit' className="submit-btn btn-block">Submit</Button>   
                                      
                                
                            </div>
                        </div> 
                    </div> 
              </Form>
        </section>
  )
}

export default StartupForm
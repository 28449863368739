import React from 'react';
import { useState, useEffect } from 'react';
import { client } from '../../client';
import { Link } from 'react-router-dom';
import './Blog.scss';
import {Helmet} from "react-helmet";

function Blog() {
    const[posts, setPosts] = useState([])

    useEffect(() => {
        client.fetch(
            `*[_type == "post"] {
                title, 
                slug,
                body,
                mainImage {
                    asset -> {
                        _id,
                        url
                    },
                    alt
                }
            }`
         ).then((data) => setPosts(data)).catch(console.error)
    }, [])

    

  return (
    <>

        {/* <Helmet>
            <meta charSet="utf-8" />
            <title>Business Blogs, News, and Articles - Amicus Growth Advisors</title>
            <meta name="keyword" content="Business Consultant, Amicus Growth Advisors, go to market strategist" />
            <meta name="description" content="Stay ahead with Amicus Growth Advisors' blog. Dive into the latest business news, insightful articles, and expert opinions to keep you informed and inspired." />
            <link rel="canonical" href="https://www.amicusllp.com/blog" />
        </Helmet> */}

    <h2 className='related-article'>Related Articles</h2>
        <div className='blog-container'>
            {posts.slice(0, 3).map((post) => (
                <article key={post.slug.current}>
                    <div className='blog-image'><img src={post.mainImage.asset.url} alt={post.title}/></div>
                    <div>
                    <h4 style={{paddingTop:'15px'}}>{post.title}</h4>
                    </div>
                     
                   
                    {/* // <h6>{post.categories.title}</h6> */}
                    <Link to={`/blog/${post.slug.current}`}>
                        <div>
                        <p className='blog-button'>Read Article</p>
                        {/* <img src='./arrow-solid-white.svg' alt='button-svg-icon' /> */}
                        </div>
                    </Link>
                </article>
            ))} 
        </div>
        </>
   
  )
}

export default Blog
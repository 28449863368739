import React from 'react'
import './AfterBanner.css'
// import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
// import { NavLink, Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function AfterBanner() {
  return (
    <div className='afterBanner'>
        <div className='afterBanner_container'>
           
              <div className='afterBanner_card_one'>
                 <HashLink smooth to='/services/business-transformation-consultants'> <h3 className='afterBanner-link'> Business<span style={{color:'#A4A4A4'}}><br/>Transformation</span></h3></HashLink>
              </div>
              
              <div className='afterBanner_card_three'>
            <HashLink smooth to='/services/investor-relations'><h3 className='afterBanner-link'>Investor<br/><span style={{color:'#A4A4A4'}}>Relations</span></h3></HashLink>
             </div>

            <div className='afterBanner_card_two'>
            <HashLink smooth to='/services/business-diagnostics-and-strategic-business-consultant'><h3 className='afterBanner-link'>Business <span style={{color:'#A4A4A4'}}><br/>Diagnostic</span></h3></HashLink>
            </div>
           

            <div className='afterBanner_card_three'>
            <HashLink smooth to='/services/sme-ipo-consultants'><h3 className='afterBanner-link'>SME<br/><span style={{color:'#A4A4A4'}}>IPO</span></h3></HashLink>
            {/* <p style={{fontSize:'0.9rem'}}>Helping fast moving innovators scale with purpose.</p> */}
            {/* <ArrowOutwardIcon style={{fontSize:'35px', color:'#5EC5C3'}}/> */}
            </div>

        </div>
    </div>
  )
}

export default AfterBanner
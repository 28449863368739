import React from 'react'
import {useParams} from "react-router-dom"
import { useState, useEffect } from 'react';
import { client } from '../../client';
import BlockContent from "@sanity/block-content-to-react"
import './SinglePost.scss'
import Blog from './Blog';
import {Helmet} from "react-helmet";
 

function SinglePost() {

  const[singlePost, setSinglePost] = useState([])
  const[isLoading, setIsLoading] = useState(true)
  const {slug} = useParams()

  useEffect(() => {
    client.fetch(
      `*[slug.current == "${slug}"] {
        title,
        body,
        metaTitle,
        metaKeyword,
        metaDesc,
        metaLink,
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        }
      }`
    ).then((data) => setSinglePost(data[0]))
    setIsLoading(false)
  }, [slug]) 

  return (
    <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>{singlePost.metaTitle}</title>
            <meta name="keyword" content={singlePost.metaKeyword} />
            <meta name="description" content={singlePost.metaDesc}  />
            <link rel="canonical" href={`${singlePost.metaLink}`} />
        </Helmet>

    <div className='singlePost'>
      {isLoading ? <h1>Loading...</h1>:
      <section className='singlePost-wrapper'>
        <div className='singlePost-heading'>
          <h1>{singlePost.title}</h1>
        </div>
        
        {singlePost.mainImage && singlePost.mainImage.asset &&(
          <img src={singlePost.mainImage.asset.url} alt={singlePost.title} title={singlePost.title}/>
        )}
        
        {/* <p>By Samyak</p>  */}
        <div className='singlePost-mainContent'>
          <BlockContent
          blocks={singlePost.body}
          projectId="7p0mupoj"
          dataset="production"
          />
        </div>
          
        {/* <Link to='/blog'>
        <button style={{backgroundColor:'transparent',border:'none',color:'#566dc8' }}>
          Read more articles
        </button>
        </Link> */}
      </section>
      }
    </div>

    <Blog/>

    </>
  )
}

export default SinglePost
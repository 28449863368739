import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useEffect } from 'react';
import { client } from '../../client';
import { Link } from 'react-router-dom';
import './CustomCarousel.scss';
// import {useNavigate} from "react-router-dom"

function CustomCarousel() {

  const[reports, setReports] = useState([])

  useEffect(() => {
      client.fetch(
          `*[_type == "report"] {
              title,
              slug,
              body,
              mainImage {
                  asset -> {
                      _id,
                      url
                  },
                  alt
              }
          }`
       ).then((data) => setReports(data)).catch(console.error)
  }, [])

  return (
    <Carousel
        // activeIndex = {index}
        // onSelect = {handleSelect}
        nextIcon = "Next"
        prevIcon = {false} 
        indicators = {false}
        className='homepage-carousel'
    >

      {reports.slice(0, 3).map((post) => (
      <Carousel.Item key={post.slug.current}>
      <img src={post.mainImage.asset.url} alt={post.title} />
        <Link to={`/report/${post.slug.current}`}>
            <div className='report-button-wrapper'>
                <p >View Report</p>
                <img src="./arrow-solid-white.svg" alt="Report Details Button" className='report-btn' />
            </div>
        </Link>
        <Carousel.Caption className='text-start'>
        <h2 className='homepage-carousel-h2'>{post.title}</h2> 
          {/* <h2 className='homepage-carousel-h2'>Quarter-4 <br/>Annual Result <br/>FY-2023 <br/>Logistics Sector</h2> */}
        </Carousel.Caption>
      </Carousel.Item>
      ))} 

      {/* <Carousel.Item>
        <img src="./images/report-q4.jpg" alt="report-images" />
          <div className='report-button-wrapper' onClick={() => goToReport1()}>
                <p >View Report</p>
                <img src="./arrow-solid-white.svg" alt="Report Details Button" className='report-btn' />
            </div>
        <Carousel.Caption className='text-start'>
          <h2 className='homepage-carousel-h2'>Quarter-4 <br/>Annual Result <br/>FY-2023 <br/>Logistics Sector</h2>
        </Carousel.Caption>
      </Carousel.Item> */}


      {/* <Carousel.Item>
        <img src="./images/report-q3.jpg" alt="" />
              <div className='report-button-wrapper' onClick={() => goToReport2()}>
                  <p>View Report</p>
                  <img src="./arrow-solid-white.svg" alt="Report Details Button" className='report-btn' />
              </div>
        <Carousel.Caption>
        <h2 className='homepage-carousel-h2'>Quarter-3 Result <br/>(FY-2023) <br/>Logistics Sector</h2>
        </Carousel.Caption>
      </Carousel.Item> */}


      {/* <Carousel.Item>
        <img src="./images/report-valve2.jpg" alt="reports-valve-manufacturing" />
            <div className='report-button-wrapper' onClick={() => goToReport1()}>
                    <p>View Report</p>
                    <img src="./arrow-solid-white.svg" alt="Report Details Button" className='report-btn' />
            </div>
        <Carousel.Caption>
        <h2 className='homepage-carousel-h2'>Quarter-4 <br/>Annual Result <br/>FY-2023 <br/>Valve Manufacturing</h2>
        </Carousel.Caption>
      </Carousel.Item> */}

    </Carousel>
  );
}

export default CustomCarousel;
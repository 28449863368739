import {createClient} from '@sanity/client'

export const client = createClient({
    projectId: '7p0mupoj',
    dataset: 'production',
    useCdn: true, 
    apiVersion: '2023-08-12', 
  }) 



//   export async function getPosts() {
//     const posts = await client.fetch('*[_type == "post"]')
//     return posts
//   }
  
//   export async function createPost(post: Post) {
//     const result = client.create(post)
//     return result
//   }
  
//   export async function updateDocumentTitle(_id, title) {
//     const result = client.patch(_id).set({title})
//     return result
//   }
import React from 'react';
import './IndustryTop.scss'
import { Container, Row, Col } from 'react-bootstrap';

function IndustryTop({topHeading, topPara1, topPara2, img}) {
  return (
    <div className='IndustryTop'>
      <Container>
        <Row>
            <Col>
            <div className='IndustryTop-content'>
                <h2>{topHeading}</h2> 
                <p>{topPara1}</p>
                <p>{topPara2}</p>
            </div>
            
            </Col>
            <Col className='IndustryTop-image-wrapper'>
              
                  <img src={img} alt='Industry-images' />
             
            
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default IndustryTop
import React from 'react';
import './ServiceLeft.scss';
import {Helmet} from "react-helmet";
import Top from './Top';
import ContentArea from './ContentArea';
import ServiceOffering from './ServiceOffering';
import FAQ from './FAQ';

function BusinessDiagnostic() {
  const faqDesign = {
    width: '85%',
    paddingBottom: '45px',
  }
  const ldJson = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What is business diagnostics, and how can it benefit my company?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Business diagnostics is an evaluation process identifying strengths, weaknesses, and growth opportunities in your company's operations, strategies, and resources. It benefits your company by providing actionable insights and strategies for performance improvement, productivity enhancement, and sustainable growth."
      }
    },{
      "@type": "Question",
      "name": "What types of businesses can benefit from your strategic business consultant services?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our strategic business consulting services benefit a broad spectrum of businesses, including startups, small and medium enterprises, and large corporations across various industries. Any business facing transitions, aiming for growth, or seeking strategic direction can benefit from them."
      }
    },{
      "@type": "Question",
      "name": "How do your sales consulting services differ from general sales training?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Unlike general sales training, our sales consulting services offer customised solutions tailored to your business's unique challenges and goals. We focus on improving sales processes, strategies, and team performance through a detailed analysis and action plan specific to your needs."
      }
    },{
      "@type": "Question",
      "name": "Can you describe the process of your operations diagnostics service?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our operations diagnostics service involves a comprehensive review of your operational processes to identify inefficiencies and areas for improvement. We then develop a customised plan focusing on process optimization, technology integration, and best practice implementation to enhance efficiency and reduce costs."
      }
    },{
      "@type": "Question",
      "name": "What aspects of HR diagnostics do you focus on, and how can it improve my business?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We focus on organisational structure, talent management, performance systems, and employee engagement. Our HR diagnostics can improve HR policies, improve productivity, increase employee satisfaction, and align human resources with business goals."
      }
    },{
      "@type": "Question",
      "name": "How do you customise your business diagnostic services for different industries?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We tailor our business diagnostic services based on industry-specific challenges, trends, and dynamics. Our industry expertise ensures that our assessments and recommendations are practical, relevant, and effective in driving growth and performance in your specific industry context."
      }
    },{
      "@type": "Question",
      "name": "What qualifications and experience do your strategic business consultants have?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our strategic business consultants have advanced degrees and extensive experience across various industries. They combine analytical skills, strategic thinking, and practical experience to provide deep insights and actionable strategies supported by continuous training in business trends and best practices."
      }
    },{
      "@type": "Question",
      "name": "Are your business diagnostic services suitable for small and medium-sized enterprises?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, our services benefit small and medium-sized enterprises (SMEs). We provide tailored insights and strategies to help SMEs achieve growth, improve efficiency, and enhance competitiveness with scalable and sustainable solutions."
      }
    },{
      "@type": "Question",
      "name": "What makes Amicus Growth Advisors unique in business diagnostics and consulting?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Amicus Growth Advisors stands out for our holistic approach, deep industry expertise, and focus on delivering actionable insights and tangible results. Our methodology, global expertise, and collaborative client relationships ensure innovative, practical strategies aligned with your company's vision and goals."
      }
    }]
  
  };

  return (
    <div> 
      <Helmet>
            <meta charSet="utf-8" />
            <title>Strategic Business Consultant | Business Diagnostics | Amicus Growth Advisors</title>
            <meta name="keyword" content="business diagnostics, strategic business consultant, strategic business consulting, sales consultant, operations diagnostics, hr diagnostics, business diagnostic services
" />
            <meta name="description" content=" Expert in business diagnostics, offering strategic business consulting in sales, HR, and operations. Our business diagnostic services ensure holistic growth.
" />
            <link rel="canonical" href="https://www.amicusllp.com/services/business-diagnostics-and-strategic-business-consultant" />
            <script type="application/ld+json">
                {JSON.stringify(ldJson)}
            </script>
        </Helmet>

        <Top 
        image = './About-banner.png' 
        text="Business Diagnostics"
        heading="Business Diagnostics Service"
        desc="Our Diagnostic Services offers a range of B2B services to collaborate with other businesses or organizations."
        YTembed="t9cWeL7CtkU"
        /> 

        <ContentArea 
        h1="Business Diagnostics/Strategic Business Consultant"
        p1="Business diagnostics and strategic business consulting are essential tools for businesses looking to assess their current performance and plan for future growth. Business diagnostics involve a thorough analysis of a company's operations, finances, market position, and overall strategy. This assessment helps identify areas of strength and weakness, allowing businesses to make informed decisions and implement improvements."
        
        p12="Strategic business consultants work closely with companies to develop actionable strategies, aligning their goals with market trends and competitive dynamics. They provide expert advice on market entry, expansion plans, cost optimization, and operational efficiency. By leveraging business diagnostics and strategic consulting, companies can drive sustainable growth, improve profitability, and stay ahead in a dynamic marketplace."
        p13=""
        img="../BD.jpg"
        altTag="business-Diagnostics-service"
        h2="How do we diagnose a business?"
        p2="Diagnosing a business involves in-depth analysis and assessment of various aspects of the company to identify areas of improvement and potential challenges. The process typically begins by conducting a comprehensive review of the company's financial statements, performance metrics, and market position."
        p22="Additionally, a thorough examination of operational processes, organizational structure, and customer feedback is conducted to identify any bottlenecks or areas that require optimization. Industry benchmarks and market trends are also taken into account. The findings from these analyses guide the development of strategies to address identified issues and drive business growth. Diagnosing a business requires a holistic approach, considering both internal and external factors to obtain an accurate understanding of the company's current state."
        h3="Why is business diagnosis important?"
        p3="Business diagnosis is crucial because it provides valuable insights into the various aspects of a company, allowing for a thorough understanding of its strengths, weaknesses, opportunities, and threats. By conducting a systematic analysis, businesses can uncover hidden inefficiencies, identify areas for improvement, and develop strategies to overcome challenges. It helps in making informed decisions related to resource allocation, process optimization, market positioning, and growth initiatives."
        p32="Moreover, business diagnosis provides a benchmark for measuring performance and progress over time. It enables businesses to stay competitive, adapt to changing market conditions, and seize new opportunities. Ultimately, business diagnosis is an integral part of strategic planning, ensuring that companies can maximize their potential and achieve long-term success."
        h4="What does a strategic business consultant do?"
        p4="A strategic business consultant plays a crucial role in helping companies navigate challenges, capitalize on opportunities, and drive growth. These professionals bring their expertise and experience to provide actionable advice and guidance to businesses. Their responsibilities include conducting in-depth market research to identify trends, competitive landscape, and customer preferences. They analyze the company's internal processes, organizational structure, and financial performance to identify areas of improvement."
        p42="Based on their findings, they help develop and implement strategies to enhance operations, optimize resources, and increase profitability. They also provide guidance on market entry strategies, expansion plans, and product/service development. Overall, a strategic business consultant acts as a trusted advisor, assisting companies in making informed decisions and achieving their business goals."       
       />
  
        <ServiceOffering 
        heading="Business Diagnostics Service Offering"
        s1="Strategic Analysis"
        s2="Customer Experience Evaluation"
        s3="Organizational Structure Review"
        s4="Performance Metrics Development"
        />
<div style={{display:'flex', justifyContent:'center'}}>
          <div style={faqDesign}>
          <h2 style={{padding:'35px 0px'}}>Frequently Asked Questions</h2>
      <FAQ 
          th1="What is business diagnostics, and how it can benefit my company?"
          ds1="Business diagnostics is an evaluation process identifying strengths, weaknesses, and growth opportunities in your company's operations, strategies, and resources. It benefits your company by providing actionable insights and strategies for performance improvement, productivity enhancement, and sustainable growth."
          th2="Can you describe the process of your operations diagnostics service?"
          ds2="Our operations diagnostics service involves a comprehensive review of your operational processes to identify inefficiencies and areas for improvement. We then develop a customised plan focusing on process optimization, technology integration, and best practice implementation to enhance efficiency and reduce costs."
          th3="How do you customise your business diagnostic services for different industries?"
          ds3="We tailor our business diagnostic services based on industry-specific challenges, trends, and dynamics. Our industry expertise ensures that our assessments and recommendations are practical, relevant, and effective in driving growth and performance in your specific industry context"
          th4="What aspects of HR diagnostics do you focus on, and how can it improve my business?"
          ds4="We focus on organisational structure, talent management, performance systems, and employee engagement. Our HR diagnostics can improve HR policies, improve productivity, increase employee satisfaction, and align human resources with business goals"
          th5="Are your business diagnostic services suitable for small and medium-sized enterprises?"
          ds5='Yes, services benefit small and medium-sized enterprises (SMEs). We provide tailored insights and strategies to help SMEs achieve growth, improve efficiency, and enhance competitiveness with scalable and sustainable solutions.'
          th6="What makes Amicus Growth Advisors unique in business diagnostics and consulting?"
          ds6="Amicus Growth Advisors stands out for our holistic approach, deep industry expertise, and focus on delivering actionable insights and tangible results. Our methodology, global expertise, and collaborative client relationships ensure innovative, practical strategies aligned with your company's vision and goals."
        />
      </div>
      </div>
        

    </div>
  )
}

export default BusinessDiagnostic
import React from 'react';
import './TeamCard.css';
// import { Link } from 'react-router-dom';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function TeamCard({name, work, image, linkedin }) {
  return (
    <div>
       
          <div className='team-home-img-card'>
              <a href={linkedin} target='blank'> <div className='team-home-img-card-top'>
                  <img src={image} alt='Our Team Image - SK Jain'/>
                </div></a>
                <div className='team-home-img-card-bottom'> 
                    <div className='team-home-img-card-bottom-content'>
                        <div className='team-card-bottom-content-left'>
                            <h3>{name}</h3>
                            <h4>{work}</h4>
                          </div>    
                          <div className='team-card-bottom-content-right'>
                          <a href={linkedin} target='blank'><LinkedInIcon style={{backgroundColor:'#0A66C2', fontSize:'38px', color:'white'}}/></a>
                          </div>
                    </div> 
                </div> 
          </div>
    </div>
   
  )
}

export default TeamCard
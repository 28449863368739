import React from 'react';
import './BannerAnimation.css';
import YoutubeEmbed from './YoutubeEmbed';
// import { NavLink, Link } from "react-router-dom";



function BannerAnimation() {
  return (
    <div className='banner-animation'>
        <div className='banner-left'>
            <div className='Mega-div'>
                <div className='Mega-left'>
                    <div className='box'>
                        <img src='./arrow-solid.svg' alt='arrow-solid-svg' className='aaa'/>
                    </div>
                    
                </div>
                <div className='Mega-right'>
                    
                    <div className='Mega-right-box-1'>
                        <h2>TRANSFORM</h2>
                       
                    </div>
                    <div className='Mega-right-box-2'>
                         <h2>GROW</h2>
                    </div>
                    <div className='Mega-right-box-3'>
                        <h2>SUCCEED</h2>
                    </div>

                    <a href="https://drive.google.com/file/d/1-8bUX6oK2mNowdMYyO9DlDNRPgT-nk9i/view" target="_blank">
                    <button className='corporate-deck'>Corporate Deck</button>
          </a>
                </div>
            </div>


            <div className='Arrow-div'></div>
            
        </div>
        <div className='banner-right'>
            <YoutubeEmbed embedId="iCfPw0h_5fo" /> 
                {/* <img src='./banner.jpg' alt='Banner-Images'/> */}
           
                    
        </div> 
    </div>
  )
}

export default BannerAnimation
import React from 'react';
import './ReportPage.scss';
import { Link} from "react-router-dom";
import { useState, useEffect } from 'react';
import { client } from '../../client';
// import PageTop from '../Assets/pageTop/PageTop';
// import PageTop from '../Assets/pageTop/PageTop';

function ReportPage() {

  const[reports, setReports] = useState([])

    useEffect(() => {
        client.fetch(
            `*[_type == "report"] { 
                title,
                slug, 
                body,
                mainImage {
                    asset -> {
                        _id,
                        url
                    },
                    alt
                    
                }
            }`
         ).then((data) => setReports(data)).catch(console.error)
    }, [])


  return (
    <div className='blogSection'>
        {/* <PageTop
        
        headingFirstHalf="OUR "
        headingSecondHalf="REPORTS"
        belowHeading='Dive into our knowledge pool, broaden your horizon with every read.'
        paragraph="You want to stay informed but overwhelmed with all the content across the
        web? Need a curated reading ??? We’ve got you covered. Explore our blogs
        that help you navigate industry trends, unlock new knowledge and offer
        unique viewpoints."
        />  */}

      {reports.map((report) => (
                 <div class="blogSection-container" key={report.slug.current}>
                 <div class="blogSection-box-wrapper"> 
                     <div class="blogSection-img-box">
                         <img src={report.mainImage.asset.url} alt={report.title} style={{width:'100%'}}/>
                     </div>
                     <div class="blogSection-content-box">
                         <h4>{report.title}</h4>
                         <Link to={`/report/${report.slug.current}`} style={{color:'white',fontWeight:'600'}}>See Report</Link>
                         
                     </div>
                     
                 </div>
             </div>
            ))} 
        

    </div>
  )
}

export default ReportPage
import React from 'react';
import './HomeAbout.css';
import DoneAllIcon from '@mui/icons-material/DoneAll';
// import { Link } from 'react-router-dom';
import {useNavigate} from "react-router-dom"


function HomeAbout() {
  
    const navigate = useNavigate();
    const goToContact = () =>{
      navigate("/company")
    };

  return (
    <>
    <div className='homeAbout'>
     
      {/* <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
         </ul> */}
         {/* <a href="/services">abc</a> */}
      <div className='homeAbout-container'>
          <div className='homeAbout-left-content'>
                  <h2 >WHO WE ARE ? </h2>
                  <h5>Your Growth Partner</h5>
                  <p>At Amicus we serve our clients at every level of their organizations. Our approach of
        ‘Transform-Grow-Succeed’ lets us diagnose copious challenges faced by businesses,
        provide feedback alongwith actionable strategies thereby improving efficiencies by a
        mile.</p>
                   
              <div className='homeAbout-link'>
                  
                <button onClick={() => goToContact()}>Read more</button>
                  
                <img src='./arrow-solid-white.svg' alt='button-svg-icon' className='homeAbout-link-img' onClick={() => goToContact()}/> 
              </div> 
                  
 
          </div>
          <div className='homeAbout-content-container'>
                <div className='homeAbout-content'>
                    <div className='homeAbout-content-wrapper'> 
                    <DoneAllIcon style={{fontSize:'35px'}}/> 
                    {/* <FileDownloadDoneIcon style={{fontSize:'35px'}}/> */}
                    <h2>Your Execution Partner </h2>
                    </div> 

                    <div className='homeAbout-content-wrapper'> 
                    <DoneAllIcon style={{fontSize:'35px'}}/> 
                    {/* <FileDownloadDoneIcon style={{fontSize:'35px'}}/> */}
                    <h2>Technology Driven Approach</h2>
                    </div>

                    <div className='homeAbout-content-wrapper'> 
                    <DoneAllIcon style={{fontSize:'35px'}}/> 
                    {/* <FileDownloadDoneIcon style={{fontSize:'35px'}}/> */}
                    <h2>Empowering Sustainable Future </h2>
                    </div>

                    <div className='homeAbout-content-wrapper'> 
                    <DoneAllIcon style={{fontSize:'35px'}}/> 
                    {/* <FileDownloadDoneIcon style={{fontSize:'35px'}}/> */}
                    <h2>Fostering Collaborative Synergy</h2>
                    </div>
              
                </div>
                
          
          </div>
        </div>
        <ul className="homeAbout-circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li> 
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
         </ul> 
        
        </div>

       
     
        </>
   
  )
}

export default HomeAbout;
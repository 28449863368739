import React from 'react';
import './TeamHome.css';
import TeamHome from './TeamHome';


function TeamSection({heading}) {
  return (
     <div className='team-home'> 
        <div className='team-home-content'>
          <h2 style={{marginBottom:'-30px'}}>{heading}</h2> 
        </div>
        <TeamHome/> 
        


    </div>
    
  )
}

export default TeamSection
import React from 'react';
import SimpleImageSlider from "react-simple-image-slider";
import './Slider.scss';


function Slider() {

    const images = [
        { url: "/GrowBannerHD1.jpg" },
        { url: "/GrowBannerHD3.jpg" },
        { url: "/GrowBannerHD4.jpg" },  
      ];



  return (
     <div >
        <SimpleImageSlider
        width="100%"
        height= "91vh"
        images={images}
        showBullets={true}
        showNavs={true}
        autoPlay={true}
        autoPlayDelay={3.9}
        responsive={true}
      />
    </div> 
    
  )
}

export default Slider
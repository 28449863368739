import React from 'react';
import './ServicePage.scss';
import ServiceLeft from './ServiceLeft';
// import Accordion from 'react-bootstrap/Accordion';
// import MyAccordion from '../Assets/accordionNew/MyAccordion';
import PageTop from '../Assets/pageTop/PageTop';
// import Accordions from '../Assets/Accordion';
import ServiceRight from './ServiceRight';
// import { useRef } from 'react';
import {Helmet} from "react-helmet";

function ServicePage() { 

  return ( 
    <div className='service-page'>

      <Helmet>
            <meta charSet="utf-8" />
            <title>Business Consultant | Business Advisor - Amicus Growth Advisors</title>
            <meta name="keyword" content="business transformation, sme ipo consultants, startup consultant, business diagnostics service, strategic business consultant, board advisor, virtual cfo services, merger and acquisition services, esg advisory" />
            <meta name="description" content="Amicus Growth Advisors offers unparalleled services in business transformation, SME IPOs, startup consulting, diagnostics, M&A, ESG advisory, and virtual CFO. Your strategic growth partner." />
            <link rel="canonical" href="https://www.amicusllp.com/services" />
        </Helmet>
        {/* <div className='sp-content-wrapper'>
            <h1>Our Expertise</h1>
        </div> */}
        <PageTop 
         
        headingFirstHalf="OUR "
        headingSecondHalf="EXPERTISE"
        belowHeading='Gear up to Empower your journey with our tailored strategies. Your success, our mission.'
        paragraph="Navigating a sea of responsibilities with finite resources can be overwhelming for any entrepreneur or investor. This is where our range of services enters the equation. We guide you through daily obstacles, unlock significant milestones, provide a new lens to interpret challenges, and prepare you for the forthcoming game-changer in your field."
        /> 
         
        <section id='transformation'>
          <ServiceLeft 
          headingFirstHalf="Business"
          headingSecondHalf="Transformation"
          description="Drive sustainable growth and competitiveness with our strategic business transformation solutions."
          img='./business-transform.jpg'
          title1="Business Diagnostic & Process Transformation "
          desc1="Our team conducts comprehensive diagnostics to assess your business health, identify operational gaps, and streamline processes. We apply innovative solutions, ensuring optimized efficiency, enhanced performance, and long-term business sustainability."
          title3="Management Review Meeting (MRM) & Go to Market Strategy (GTM) "
          desc3="We engage in collaborative sessions with your management team, utilizing industry insights and strategic planning to formulate tailored, effective go-to-market strategies. This ensures successful market entry, competitive positioning, and sustainable growth."
          title2="Revenue & EBITDA Improvement"
          desc2="Our expertise lies in leveraging data-driven strategies to enhance your revenue and EBITDA. We focus on increasing profitability, financial stability, and creating sustained value, allowing your business to thrive in a competitive environment."
          title4= "Digital Transformation "
          desc4="We guide your business through the complexities of digital transformation, driving innovation, efficiency, and improved customer experiences. By integrating digital technologies into all areas, we unlock new revenue streams and enhance business agility. "
          title5= "Roadmap for IPO"
          desc5="We guide your business through the complexities of digital transformation, driving   innovation, efficiency, and improved customer experiences. By integrating digital technologies into all areas, we unlock new revenue streams and enhance business agility."
          />
        </section>

        <section id='smeipo'>
          <ServiceRight  
          headingFirstHalf="Small Medium Enterprise"
          headingSecondHalf="IPO"
          description="Fuel your SME's expansion and competitiveness through our strategic SME IPO fundraising solutions."
          img='./sme_ipo.jpg'
          title1="IPO Readiness Assessment"
          desc1="Our IPO readiness assessment service involves a comprehensive evaluation of your company's financial, operational, and governance structures. We meticulously analyze your readiness for going public and identify any areas that may need improvement to ensure a smooth and successful IPO journey. Our experts provide actionable recommendations and a clear roadmap to enhance compliance, transparency, and investor confidence, allowing you to enter the public markets with a solid foundation."
          title2="Pre-IPO Fundraising"
          desc2="Our pre-IPO fundraising service is designed to help you secure the necessary capital for a strong IPO launch. We work closely with your team to develop fundraising strategies tailored to your growth objectives. Leveraging our extensive network of investors, we assist in raising funds through private placements or pre-IPO rounds, ensuring your financial strength aligns with the demands of the IPO process. With our support, you can confidently navigate the challenges of going public."
          title3="Employee Stock Option Plans (ESOPs)"
          desc3="Our ESOP service focuses on designing and managing employee stock option plans that incentivize and retain top talent while aligning their interests with your company's success. We take care of the intricate details of ESOP structuring, ensuring compliance with regulatory requirements. By implementing effective ESOPs, we help you build a motivated and committed workforce as you transition to a publicly traded company, fostering a culture of shared success."
          title4="Project Management of Issue"
          desc4="Our project management service is dedicated to overseeing the entire IPO process. Our experienced project managers ensure seamless coordination among various stakeholders, including legal, financial, and underwriting teams. We meticulously plan and execute every step, reducing operational risks and ensuring that your IPO stays on track and on schedule. With our support, you can navigate the complexities of an IPO with confidence."
          title5="Investor Relations Strategy"
          desc5="Our investor relations strategy service is geared toward enhancing your communication with shareholders and potential investors. We create and implement tailored strategies that effectively convey your company's value proposition, financial performance, and growth prospects. Our experts assist in crafting compelling investor presentations, organizing informative earnings calls, and developing proactive outreach programs to nurture strong investor relationships, ultimately boosting your company's reputation in the market."
        />
        </section>
        
        <section id='diagnostic'>
          <ServiceLeft  
          headingFirstHalf="Business"
          headingSecondHalf="Diagnostic"
          description="Identify and address your business's pain points effectively with our comprehensive diagnostic services."
          img='./business-diagnostic.jpg'
          title1="Sales Diagnostic"
          desc1="Our team conducts a deep dive into your sales strategies and processes. We evaluate your go-to-market approach, customer concentration, and sales performance to uncover opportunities for growth. Our insights can help enhance salesforce effectiveness, diversify your customer base, and facilitate robust go-to-market strategies, driving your revenue growth and ensuring customer retention."
          title2="Operations Diagnostic "
          desc2="Our comprehensive review of your operational processes identifies areas for improvement. We drive operational efficiency, productivity, and cost-effectiveness, aligning your operations with strategic business goals."
          title3="Procurement Diagnostic"
          desc3="We scrutinize your procurement processes, ensuring value for money and effective supplier management. Our insights will lead to improved cost controls, strengthened vendor relationships, and sustainable procurement practices."
          title4="Technology Diagnostic"
          desc4="We evaluate your technological infrastructure, identifying gaps and opportunities for innovation. Our expertise will guide you towards digital maturity, enhanced security, and tech-driven efficiencies."
          title5="Support Function Diagnostic (HR, Finance)"
          desc5="We assess your critical support functions, optimizing HR and Finance operations. Our guidance ensures these departments effectively support business objectives, enhancing personnel management and financial stewardship."
        />
        </section>
        
        <section id='startup'>
          <ServiceRight 
          headingFirstHalf="Startup"
          headingSecondHalf="Advisory"
          description="Propel your startup to success with our insightful guidance and strategic planning services."
          img='./startup-advisory.jpg'
          title1="Fundraising and Investor Outreach"
          desc1="Our team provides strategic guidance in fundraising efforts and investor outreach. We'll help you identify potential investors, prepare for pitches, and navigate through funding rounds to secure the capital your startup needs."
          title2="Valuation & Financial Due Diligence"
          desc2="We offer expert valuation services and thorough financial due diligence. We'll ensure you understand your startup's worth, preparing you for investment negotiations and informing key financial decisions."
          title3="Virtual CFO"
          desc3="Our virtual CFO service provides your startup with expert financial oversight without the full-time commitment. We handle strategic financial planning, budgeting, and financial reporting, freeing you to focus on growing your business."
          title4="FP&A Function Management"
          desc4="We manage your Financial Planning & Analysis function, delivering insightful financial forecasts, budgeting, variance analysis, and strategic planning to guide your startup's growth and profitability."
          title5="Pitch Deck Preparation"
          desc5="Our team assists in preparing compelling pitch decks. We'll help you articulate your business model, value proposition, and growth strategy in a way that resonates with investors and sets your startup apart."

          />
        </section>

        <section id='board'>
          <ServiceLeft 
          headingFirstHalf="Board "
          headingSecondHalf="Advisory"
          description="Craft effective corporate governance strategies and insightful roadmaps with our expert board advisory."
          img='./board_advisory.jpg'
          title1="Corporate Governance & Risk Management"
          desc1="We provide board-level advisory on governance best practices and risk management. Our guidance strengthens organizational integrity, manages business risks, and ensures compliance with regulatory standards."
          title2="Board evaluation"
          desc2="Enhance your company's performance and governance with our expert board evaluation service. We assess director effectiveness, strategic alignment, and decision-making to optimize board dynamics and drive organizational success."
          title3="ESG & CSR Oversight "
          desc3="We assist boards in overseeing ESG policies, aligning business operations with sustainable and responsible practices. Our advisory enhances reputation, mitigates risks, and ensures long-term sustainability."
          title4="Financial Oversight"
          desc4="Our board advisory services encompass financial oversight, ensuring sound financial policies, effective controls, and reliable reporting. We guide boards in their role to safeguard financial stability and enhance business performance."
          title5="Independent Director and Audit Committee"
          desc5="Tap into our reservoir of skilled Independent Directors and Audit Committee experts, cultivating effective governance and financial prudence for your business. Elevate your board with experienced leaders ensuring compliance and strategic oversight."
          />
        </section>

        <section id='vcfo'>
          <ServiceRight 
          headingFirstHalf="Virtual-CFO"
          headingSecondHalf="Services"
          description="Optimize your business's financial performance with our comprehensive, remote CFO services."
          img='./virtual-cfo.jpg'
          title1="Financial Planning and Analysis (FP&A)"
          desc1="Our virtual CFO services include robust financial planning and analysis. We offer strategic budgeting, forecasting, and variance analysis to help guide your business decisions and drive growth."
          title2="Investor Relations"
          desc2="We manage and enhance your investor relations, communicating your business performance and strategies effectively to your stakeholders. This helps foster investor confidence and secure funding for your business."
          title3="Strategic Financial Advice"
          desc3="Our virtual CFO service provides crucial strategic financial advice. From capital structure decisions to profitability analysis, we assist you in navigating complex financial landscapes to ensure your business thrives."
          title5="Tax Planning and Compliance"
          desc5="We guide your tax planning and ensure compliance with the latest tax regulations. Our expertise helps optimize your tax position and minimize risks, ensuring your business stays compliant and financially sound."
          title4="Working Capital Management"
          desc4="Our virtual CFO services extend to managing your working capital. We strategize to optimize cash flow, manage receivables and payables, and ensure sufficient liquidity for smooth business operations."
          />
        </section>

        <section id='ma'>
          <ServiceLeft 
          headingFirstHalf="Merger"
          headingSecondHalf="& Acquisitions"
          description="Navigate mergers and acquisitions seamlessly with our strategic planning and implementation services."
          img='./merger&acquisition.jpg'
          title1="Due Diligence"
          desc1="Our team conducts comprehensive due diligence before any M&A transaction. We assess potential risks and opportunities to ensure a successful and beneficial merger or acquisition."
          title2="Deal Structuring & Valuation"
          desc2="We provide expertise in deal structuring and accurate business valuation, offering insights to secure favorable terms and ensuring the transaction aligns with your strategic goals."
          title3="Negotiation Support"
          desc3="Our seasoned advisors offer support during negotiation stages, leveraging their experience to advocate for your best interests and facilitate a successful deal closure."
          title4="Pitch Docket Preparation"
          desc4="Streamline your M&A journey with our meticulous pitch docket preparation, expertly compiling comprehensive insights and strategies for successful mergers and acquisitions."
          title5="Exit Strategy Planning"
          desc5="Our team helps design effective exit strategies for investors and business owners. We guide you through the process, ensuring your exit is structured to maximize returns and minimize disruptions."
          />
        </section>

        <section id='esg'>
          <ServiceRight 
          headingFirstHalf="ESG"
          headingSecondHalf="Advisory"
          description="Advance your environmental, social, and governance initiatives with our expert ESG advisory services."
          img='./esg-advisory.jpg'
          title1="BRSR (SUSTAINABILITY REPORTING) "
          desc1="We assist your business in developing comprehensive Business Responsibility and Sustainability Reports (BRSR). Our expertise will enhance your disclosures, demonstrating your commitment to sustainability and responsible practices to stakeholders."
          title2="Corporate Sustainability and Responsibility"
          desc2="Our team helps you integrate sustainability and responsibility into your corporate strategy. We devise effective CSR policies and initiatives, driving ethical business practices and contributing to long-term success."
          title3="ESG Standards & Disclosures"
          desc3="We guide you in understanding and implementing ESG standards, ensuring accurate and transparent disclosures. Our approach enhances stakeholder trust and aligns your business with global sustainability expectations."
          title4="ESG Diligence"
          desc4="We conduct thorough ESG diligence, examining your practices and policies against ESG factors. Our reviews inform strategic decisions, manage risk, and enhance your sustainability profile."
          title5="Training and Awareness"
          desc5="We deliver tailored training and awareness programs, building your team's capacity to integrate ESG considerations into daily operations. Our initiatives promote a culture of sustainability, elevating your business's ESG performance."
          />
         </section>
    </div>
  )
}

export default ServicePage
import React from 'react';
import './AfterBanner.scss';


function AfterBanner() {
  return (
    <section className='AfterBanner'>
      <div className='AB-content-wrapper'>
        <img src='./circle.png' alt='circle-img' />
          <div className='AB-heading'>
              <p style={{textAlign:'center'}}>Amicus Growth Advisors <br/>Building a <strong>Greener World Together</strong> with <strong>Grow Trees</strong></p>
              {/* <h3>Amicus Growth Advisors</h3>
              <h3>Building a Greener World Together with Grow Trees</h3> */}
          </div>
          <div className='AB-logo'>
              <img src='./GrowTreeLogo.svg' alt="Grow Tree Logo"/>
          </div>
          <div className='AB-quote'>
              <h2>“Trees are poems the earth writes upon the sky." </h2>
              <p>~ Kahlil Gibran</p>
          </div>
      </div>

       
        <div></div>

    </section>
  )
}

export default AfterBanner
import React from 'react';
import './AboutWho.scss'
import { Container, Row, Col } from 'react-bootstrap';

function AboutWho() {
  return (
    <div className='AboutWho'>
        <div className='AboutWho-container'>
        <Container>
            <Row>
            <Col xs={12} sm={12} md={12} lg={6} >
            <h2 style={{fontSize:'2.5rem', fontWeight:'600'}}>WHO WE ARE ? </h2>
            <h3 style={{fontSize:'1.3rem'}}>Your Growth Partners</h3>
            <p className="AboutWho-p">At Amicus we serve our clients at every level of their organizations. Our approach of
‘Transform-Grow-Succeed’ lets us diagnose copious challenges faced by businesses,
provide feedback alongwith actionable strategies thereby improving efficiencies by a
mile.</p>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
             <div className='AboutWho-content-wrapper'>
             <h2>Transform </h2>
             <p>Amicus objectively partners with organizations
to drive transformation roadmap through the
improvement of critical business efficiencies,
addressing structural realignments and
curtailing process ineffectiveness</p>
            </div>   
            <div className='AboutWho-content-wrapper'>
             <h2>Grow </h2>
            <p>Our qualified professionals help
companies uncover market
opportunities to identify and
incrementally steer strategic
growth initiatives</p>
            </div>  
            <div className='AboutWho-content-wrapper'>
             <h2>Succeed</h2>
             <p>Our experts work with management/
board to embed change in business
practices and processes, that help
corporations achieve and sustain
long-term growth trajectories</p>
            </div>  
            </Col >
            </Row>
        </Container>

        </div>
       
    </div>
  )
}

export default AboutWho
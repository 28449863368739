import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

import './Accordion.scss'

export default function Accordions({pageName, t1, d1, t2, d2, t3, d3, t4, d4, t5, d5}) {

  return (
    <div className={pageName} >
      <Accordion defaultActiveKey="0" >   
        <Accordion.Item eventKey="0" >
          <Accordion.Header ><h4>{t1}</h4></Accordion.Header>
          {/* <span style={{fontSize:'1.5rem'}}>{t1}</span> */}
          <Accordion.Body >
            {d1}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header><h4>{t2}</h4></Accordion.Header>
          <Accordion.Body>
          {d2}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header><h4>{t3}</h4></Accordion.Header>
          <Accordion.Body>
          {d3}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header><h4>{t4}</h4></Accordion.Header>
          <Accordion.Body>
          {d4}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header><h4>{t5}</h4></Accordion.Header>
          <Accordion.Body>
          {d5}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}


import React from 'react'
// import PlantationBanner from './PlantationBanner'
import Slider from './Slider'
import AfterBanner from './AfterBanner'
import GroveCards from './GroveCards'
import './Slider.scss'
import BottomCTA from './BottomCTA'
import AllButton from './AllButton'

// import Slideshow from './Slideshow'

function Plantation() {
  return (
    <div>
        {/* <PlantationBanner/>  */}
        <div className='Plantation-content'>
            <h2>Let The <br/>Earth Breathe.</h2>
            <p style={{paddingBottom:'25px'}}>In every tree we plant, we sow a seed of hope for our planet's future.</p>
            {/* <AllButton/> */}
            <a href="https://www.grow-trees.com/plant/greet-new-desktop.php?promotions=AMICUS2024&MP_ID=146&MC_ID=571" target="blank"  ><AllButton cta="Let’s grow a sustainable future" /></a>
            {/* <a href="https://www.grow-trees.com/projectdetails.php?id=152" target="blank"><button>Let’s grow a sustainable future</button></a> */}
           
        </div>
          
        
        <Slider/>
       <AfterBanner/>
       <GroveCards/>
       <BottomCTA/>
    </div> 
  )
}

export default Plantation
import React from 'react';
import './AboutPage.scss';
import PageTop from '../Assets/pageTop/PageTop';
import AboutBanner from './AboutBanner';
import AboutWho from './AboutWho';
import AboutValues from './AboutValues';
import TeamHome from '../home/TeamHome/TeamHome';
// import TeamCard from '../home/TeamHome/TeamCard';
// import TeamSection from '../home/TeamHome/TeamSection';
import AboutTeam from './AboutTeam';
import TeamSection from '../home/TeamHome/TeamSection';
import {Helmet} from "react-helmet";

function AboutPage() {
  return (
    <div>

        <Helmet>
            <meta charSet="utf-8" />
            <title>About Us | Business Consultant - Amicus Growth Advisors</title>
            <meta name="keyword" content="Business Consultant, Amicus Growth Advisors, go to market strategist" />
            <meta name="description" content="Discover Amicus Growth Advisors: A leading business consultancy specializing in transformative strategies, expert guidance, and custom solutions for dynamic business growth." />
            <link rel="canonical" href="https://www.amicusllp.com/company" />
        </Helmet>

        <PageTop
        headingFirstHalf="ACHIEVING "
        headingSecondHalf="GOALS"
        belowHeading='Gear up for the next!'
        paragraph="Facing the challenges of entrepreneurship or investment? Juggling countless tasks with finite resources? You've come to the right place. We're committed to helping you navigate through the everyday obstacles, unlock your true potential, see the business world from a unique perspective, and gear you up for the next big leap. Discover how we can help transform your business journey into an extraordinary success story."
        /> 
        <AboutBanner  
        image = './About-banner.png'
        quote='The Greater danger for most of us lies not in setting our aim too high and falling short; but in setting our aim too low, and achieving our mark.'
        
        />

        <AboutWho />
        <AboutValues />
        <AboutTeam 
         heading='OUR TEAM'/>
        {/* <TeamSection/> */}
       
       
    </div>
  )
}

export default AboutPage
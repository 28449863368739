import React from 'react';
import './AboutValues.scss'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import HandshakeIcon from '@mui/icons-material/Handshake';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

function AboutValues() {
  return (
    <div className='AboutValues'>
        <div className='AboutValues-wrapper'>
            <div className='AboutValues-top'>
                <div className='AboutValues-top-box'>
                    <h2>CORE VALUES</h2>
                    <p>Integrity in every action. Innovation as our compass. Collaboration for collective success. Excellence as our standard. Sustainability in our strides.</p>                </div>
            </div>
            <div className='AboutValues-bottom'>
                <div className='AboutValues-box-wrapper'>
                    <VerifiedUserIcon style={{fontSize:'50px'}}/>
                    <h2>Integrity</h2>
                    <p>Upholding honesty and ethical behavior is paramount. We apply these principles in every decision, ensuring authenticity and transparency.
</p>
                </div> 
                <div className='AboutValues-box-wrapper'>
                    <TipsAndUpdatesIcon style={{fontSize:'50px'}}/>
                    <h2>Innovation</h2>
                    <p>Our dedication to fostering creativity is unwavering. We employ innovation as our guiding compass, driving new ideas and solutions.
</p>
                </div>
                <div className='AboutValues-box-wrapper'>
                    <HandshakeIcon style={{fontSize:'50px'}}/>
                    <h2>Collaboration</h2>
                    <p>We firmly believe in the power of unity. Shared efforts lead to collective victories, fostering a collaborative culture for success and inspiration.</p>
                </div>
                <div className='AboutValues-box-wrapper' style={{border:"none"}}>
                    <EmojiEventsIcon style={{fontSize:'50px'}}/>
                    <h2>Excellence</h2>
                    <p>We're committed to high-quality performance in all our actions. Expect nothing less than the best from our services and team.</p>
                </div>
            </div>

        </div>

    </div>
  )
}

export default AboutValues
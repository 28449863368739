import React from 'react';
import './ContactPage.scss';
import PageTop from '../Assets/pageTop/PageTop';
import ContactForm from './ContactForm';
import { Col, Container, Row } from 'react-bootstrap';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {Helmet} from "react-helmet";

function ContactPage() {
  return (
    <div className='contactPage'>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Contact Us  - Amicus Growth Advisors</title>
            <meta name="keyword" content="Business Consultant, business transformation, sme ipo consultants, startup consultant, business diagnostics service, strategic business consultant" />
            <meta name="description" content="Reach out to Amicus Growth Advisors for expert business transformation, SME IPO guidance, startup consulting, and strategic diagnostics. We're here to assist you." />
            <link rel="canonical" href="https://www.amicusllp.com/contact" />
        </Helmet>

        <PageTop

          headingFirstHalf="GET "
          headingSecondHalf="IN TOUCH"
          belowHeading="Connect with us. Together lets architect the future of your business."
          paragraph="Every entrepreneur or investor is juggling a myriad of roles. With countless duties and limited resources, finding the right support is essential. That's where our team can help. We aid in overcoming everyday hurdles, celebrate your victories, help see the world from unique angles, and gear you up for your next major step. Get in touch with us today to start your journey towards the extraordinary."
        /> 
      
        <Container className='contactPage-form'>
          <Row className='were'>
            <Col className='contactPage-form-left'>
              <ContactForm />
            </Col >  
            <Col className='contactPage-form-right'>
              <div className='contactPage-details'>
              
                <div className='contactPage-details-content'>
                <h2>Contact Us</h2>
                    <div className='contactPage-content-container'>
                      <div className='contactPage-content-wrapper'>
                           <div className='contactPage-icon-wrapper'><LocationOnIcon/></div>
                                <div className='contactPage-text-wrapper'>
                                    <h5>Head Office</h5>
                                    <p>Mumbai</p> 
                            </div>
                      </div>

                      <div className='contactPage-content-wrapper'>
                           <div className='contactPage-icon-wrapper'><EmailIcon/></div>
                                <div className='contactPage-text-wrapper'>
                                    <h5>Business Enquiries</h5>
                                    <p>business@amicusllp.com</p> 
                            </div>
                      </div>

                      <div className='contactPage-content-wrapper'>
                           <div className='contactPage-icon-wrapper'><AddIcCallIcon/></div>
                                <div className='contactPage-text-wrapper'>
                                    <h5>Let's Talk</h5>
                                    <p>+91 9820075360</p> 
                            </div>
                      </div>

                      <div className='sidebar-social-icons'>
                                {/* <a target='_blank' href='https://twitter.com/i/flow/login?redirect_after_login=%2FAmicusllp' style={{color:'black'}}><TwitterIcon style={{fontSize:'32px'}}/></a> */}
                                <a href='https://www.linkedin.com/company/amicus-insolvency-turnaround-services-llp/' target='_blank' style={{color:'white'}}><LinkedInIcon style={{fontSize:'32px'}}/></a>
                                <a target='_blank' href='https://www.instagram.com/amicusgrowthadvisors/?igshid=YmMyMTA2M2Y%3D' style={{color:'white'}}><InstagramIcon style={{fontSize:'32px'}}/></a>

                                <a href='whatsapp://send?text=Thankyou for contacting Us!&phone=+919820075360' target='_blank' style={{color:'white'}}><WhatsAppIcon style={{fontSize:'32px'}}/></a>
                            </div>

                    

                     
                       

                        
                        
                      
                      </div>
                
                
                {/* <h5>Get in Touch</h5>
                <p>info@amicusllp.com</p>
                <h5>Get in Touch</h5>
                <p>info@amicusllp.com</p> */}
                </div>
                
              </div>
            </Col>
          </Row>
        </Container>
    
    </div>
  )
}

export default ContactPage
import React from 'react';
import { useState, useEffect } from 'react';
import { client } from '../../client';
import { Link } from 'react-router-dom';
import './ReportRelated.scss'

function ReportRelated() {
    const[reports, setReports] = useState([])

    useEffect(() => {
        client.fetch(
            `*[_type == "report"] {
                title,
                slug,
                body,
                mainImage {
                    asset -> {
                        _id,
                        url
                    },
                    alt
                }
            }`
         ).then((data) => setReports(data)).catch(console.error)
    }, [])

    

  return (
    <>
    <h2 className='related-article'>Related Reports</h2>
        <div className='blog-container'>
            {reports.slice(0, 3).map((post) => (
                <article key={post.slug.current}>
                    <div className='blog-image'><img src={post.mainImage.asset.url} alt={post.title}/></div>
                    <div>
                    <h4 style={{paddingTop:'15px'}}>{post.title}</h4>
                    </div>
                    
                   
                    {/* // <h6>{post.categories.title}</h6> */}
                    <Link to={`/report/${post.slug.current}`}>
                        <div>
                        <p className='blog-button'>Read Article</p>
                        
                        {/* <img src='./arrow-solid-white.svg' alt='button-svg-icon' /> */}
                        </div>
                    </Link>
                </article>
            ))} 
        </div>
        </>
   
  )
}

export default ReportRelated
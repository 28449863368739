import React from 'react';
import './GroveCards.css';

function GroveCards() {
  return (
    <div className='GroveCards-container'>
      <div className='GC-heading-container'>
        <p>Trees for <strong>wildlife</strong>, sustainability for all <br/><strong>plant now !</strong> </p>
      </div>
      
        <div className='GC-card-container'>
          <article className='GC-card-article'>
            <img src='./tiger.jpg' alt="Grove cards" className='GC-card-img'/>
            <div className='GC-card-data'>
              <span className='GC-card-desc'>West Bengal</span>
              <h2 className='GC-card-title'>Trees for Tigers</h2>
              <a href="https://www.grow-trees.com/plant/greet-new-desktop.php?promotions=AMICUS2024&MP_ID=13&MC_ID=571" target='___blank' className='GC-card-button'>Join the Movement</a>
            </div>
          </article>

          <article className='GC-card-article'>
            <img src='./leopard1.jpg' alt="Grove cards" className='GC-card-img' />
            <div className='GC-card-data'>
              <span className='GC-card-desc'>Nainital</span>
              <h2 className='GC-card-title'>Trees for Leopards</h2>
              <a href="https://www.grow-trees.com/plant/greet-new-desktop.php?promotions=AMICUS2024&MP_ID=139&MC_ID=571" target='__blank_' className='GC-card-button'>Join the Movement</a>
            </div>
          </article>

          <article className='GC-card-article'>
            <img src='./hanguls.jpg' alt="Grove cards" className='GC-card-img' />
            <div className='GC-card-data'>
              <span className='GC-card-desc'>Kashmir</span>
              <h2 className='GC-card-title'>Trees for Hanguls</h2>
              <a href="https://www.grow-trees.com/plant/greet-new-desktop.php?promotions=AMICUS2024&MP_ID=107&MC_ID=571" target='__blank__' className='GC-card-button'>Join the Movement</a>
            </div>
          </article>

          
          
        </div>
    </div>
  )
}

export default GroveCards
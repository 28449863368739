import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './ModalPopup.scss'

function ModalPopup(props) {
  return (
    <>
    </>
    // <Modal
    //   {...props}
    //   size="lg"
    //   aria-labelledby="contained-modal-title-vcenter"
    //   centered
    //   className='sk-popup'
    // >
    //     <Modal.Header closeButton className='border-0'>
    //     </Modal.Header>
    //   <Modal.Body className='text-center'>
    //     <img src="./sk-popup.jpg" alt="SK Popup" /> 
    //      <a href="https://www.theceo.in/best-business-consultants/amicus-growth-advisors" target="__blank">
    //     <button className='btn btn-sk-popup'>Read Article</button>
    //     </a>
    //   </Modal.Body>
    // </Modal>
  );
}

export default ModalPopup
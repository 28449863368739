import React from 'react';
import './AboutBanner.scss';


function AboutBanner(props) {
  return ( 
    <>
  
{/* style={{backgroundImage: `url(./About-banner.png)` }} */}
        <div className='AboutBanner' style={{ backgroundImage: `url(${props.image})` }}>
          
          {/* <img src='./About-banner.png' alt="About-Banner" /> */}
          
           
            <div className='AboutBanner-content'>
              <h2>{props.quote}</h2>
              <p style={{fontSize:'1.2rem', paddingTop:'4px'}}>- Michel Angelo</p>
           
            </div>
        </div>

        <div className='AboutBanner-arrow-container'>
            <div className='AboutBanner-arrow-wrapper'>
                <img src="./banner-icon.png" alt="banner-icon" />
            </div>
                
        </div>
            
        
    </>
  )
}

export default AboutBanner
import React from 'react';
import './Top.scss';
import { Link } from 'react-router-dom';
import YoutubeEmbed from '../home/YoutubeEmbed';
// import Background from '../career0.jpg';

function Top(props) { 
  return (
    // <div className='top' style={{ backgroundImage: `url(${props.image})` }}>
    <div className='top'>
       <div className='top-left'>
            <div className='top-left-content'>
              <p>{props.text}</p>
                <h1>{props.heading}</h1>
                <p style={{paddingTop:'10px'}}>{props.desc}</p>
            
                <div class="button" id="button-5">
                <div id="translate"></div>
                <Link to="/contact">
                <a style={{color:'white'}}>Schedule Free Consultation</a>
                </Link>
                </div>

               
            
            </div>
            </div>
            <div className='top-right-yt'>
                  <div className='top-right-yt-box'>
                    {/* <YoutubeEmbed embedId="iCfPw0h_5fo"/>  */}
                    <YoutubeEmbed embedId={props.YTembed}/>
                  </div>
            </div>
        
       
    </div> 
  )
}

export default Top
import React from 'react'
import PageTop from '../Assets/pageTop/PageTop'
import DiagnosticForm from './DiagnosticForm'

function DiagnosticPage() {
  return (
    <>
        <PageTop
        pageName="BUSINESS DIAGNOSTIC"
        headingFirstHalf="ON-BOARD "
        headingSecondHalf="DIAGNOSTICS"
        paragraph="As an entrepreneur or investor, you have a lot on your plate. With numerous responsibilities and limited resources. That's where Amicus come in."
        />
         
        <DiagnosticForm />
    </>
  )
}

export default DiagnosticPage
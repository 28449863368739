import React from 'react'

function ErrorPage() {
  return (
    <div>
       <h2>This is an Error Page</h2>
    </div>
  )
}

export default ErrorPage
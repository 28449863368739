import React from 'react';
import './BlogPage.scss'
import PageTop from '../Assets/pageTop/PageTop';
import BlogSection from './BlogSection';

function BlogPage() {
  return (
    <div className='BlogPage'>
      <PageTop
         
        headingFirstHalf="BLOG "
        headingSecondHalf="ARTICLES"
        belowHeading='Dive into our knowledge pool, broaden your horizon with every read.'
        paragraph="You want to stay informed but overwhelmed with all the content across the
        web? Need a curated reading ??? We’ve got you covered. Explore our blogs
        that help you navigate industry trends, unlock new knowledge and offer
        unique viewpoints."
        />

        {/* Blog Section Starts Here */}

        <BlogSection 
        blogImage="./blog_01.png"
        blogHeading="Why conduct a marketing audit for your small business"
        blogCategory="BUSINESS CONSULTING"
       
        />

        {/* <BlogSection 
        blogImage="./blog_02.png"
        blogHeading="When Mentorship Matters to The Start-Up Community"
        blogCategory="STARTUP ADVISORY"
        />

        <BlogSection 
        blogImage="./blog_03.png"
        blogHeading="Starting New Business? Get Help Through Consulting Services"
        blogCategory="STARTUP ADVISORY"
        />

        <BlogSection 
        blogImage="./blog_04.png"
        blogHeading="Main Skills That Business Consultants Should Possess"
        blogCategory="BUSINESS CONSULTING"
        />

        <BlogSection 
        blogImage="./blog_05.png"
        blogHeading="Why You Must Hire Startup Consultants To Get To The Next Level"
        blogCategory="TRANSFORMATION ADVISORY"
        /> */}
        
    </div>
  )
}

export default BlogPage
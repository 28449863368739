import React from 'react'
import PageTop from '../Assets/pageTop/PageTop'

function PrivacyPolicy() {
  return (
   <>
   <PageTop
        pageName="PRIVACY POLICY"
        headingFirstHalf="Privacy "
        headingSecondHalf="Policy"
        paragraph="As an entrepreneur or investor, you have a lot on your plate. With numerous responsibilities and limited resources. That's where Amicus come in."
        />
        <div className='termspage'>

            <div className='termspage-content'> 
            <h2 style={{paddingBottom:"40px", fontSize:"2.7rem"}}>Privacy Policy</h2>
            <p>AmicusLLP is committed to protecting the privacy and confidentiality of your personal information. 
This Privacy Policy outlines how we collect, use, disclose, and safeguard the information you provide to us through our website and 
during the course of our consultancy services. By accessing our website or engaging with our consultancy firm, you acknowledge 
that you have read and understood the practices described in this Privacy Policy.</p>
            <h3>Information We Collect</h3>
            <p>We may collect various types of personal information from you, including but not limited to:</p>
            <ul>
                <li>Contact information (such as your name, email address, phone number, and mailing address)</li>
                <li>Professional information (such as your job title, company name, and industry)</li>
                <li>Financial information (such as payment details for our services)</li>
                <li>Communication preferences</li>
                <li>Other information you voluntarily provide to us</li>
            </ul>
            <h3>How We Use Your Information</h3>
            <p>We may use the personal information we collect from you for the following purposes:</p>
            <ul>
                <li>To provide and deliver our consultancy services</li>
                <li>To communicate with you and respond to your inquiries</li>
                <li>To personalize and improve your experience with our firm</li>
                <li>To process payments and manage billing</li>
                <li>To send you important updates, newsletters, and promotional materials</li>
                <li>To comply with legal and regulatory obligations</li>
            </ul>
            <h3>Information Sharing and Disclosure</h3>
            <p>We may share your personal information in the following circumstances:</p>
            <ul>
                <li>With our trusted third-party service providers who assist us in operating our business and delivering our services</li>
                <li>When required by law or in response to a valid legal request</li>
                <li>In connection with a merger, acquisition, or sale of our firm or its assets</li>
                <li>With your consent or as otherwise disclosed at the time of collection</li>
            </ul>
            <p>We will not sell, rent, or lease your personal information to any third parties for their marketing purposes without your explicit consent.</p>

            <h3>Data Security</h3>
            <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, 
disclosure, alteration, and destruction. However, please note that no method of data transmission or storage is completely secure, 
and we cannot guarantee the absolute security of your information.</p>
            <h3>Your Privacy Choices</h3>
            <p>You have the right to review, update, correct, or delete your personal information at any time. You may also opt out of receiving 
promotional communications from us by following the instructions provided in the communication.</p>
            <h3>Changes to this Privacy Policy</h3>
            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We encourage 
you to review this Privacy Policy periodically for any updates.</p>
            <h3>Contact Us</h3>
            <p>If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, 
please contact us using the information provided below:<br/>
Mail: business@amicusllp.com, 
Contact – +91 98200-75360</p>
<p>By using our website or engaging with our consultancy services, you signify your acceptance of this Privacy Policy. 
If you do not agree with the practices described in this Privacy Policy, please do not access our website or provide us with 
your personal information.</p>
<p>Thank you for trusting AmicusLLP with your privacy.</p>
            </div>

        </div>
   </>
  )
}

export default PrivacyPolicy
import React from 'react';
import './ServiceCard.scss';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

function ServiceCard({heading1, heading2, oneliner, desc, link}) {
  return (
    <div className='serviceCard'>
        
            <div className='serviceCard-wrapper'>
              <div style={{display:'flex', justifyContent:'space-between',width:'100%'}}>
                  <div className='serviceCard-icon-wrapper'>
                    <PublishedWithChangesIcon className='serviceCard-icon' />
                  </div>

                  <HashLink smooth to={link} rel="noreferrer">
                      <div className='serviceCard-button'>
                          <img src='./arrow-solid-white.svg' alt='arrow-button'/>
                      </div> 
                  </HashLink>

              </div>
            <h2>{heading1}<br/>{heading2}</h2>
            <p className='paraOneliner'>{oneliner}</p>
                 
                <div className='serviceCard-bottom-content'>
                    <p>{desc}</p>    
                </div> 
                
                
                 
            </div>
        </div>
    
  )
}

export default ServiceCard
import React from 'react';
import './BlogSection.scss';
import { Link} from "react-router-dom";
import { useState, useEffect } from 'react';
import { client } from '../../client';

function BlogSection() {

  const[posts, setPosts] = useState([])

    useEffect(() => {
        client.fetch(
            `*[_type == "post"] {
                title,
                slug,
                body,
                mainImage {
                    asset -> {
                        _id,
                        url
                    },
                    alt
                }
            }`
         ).then((data) => setPosts(data)).catch(console.error)
    }, [])


  return (
    <div className='blogSection'>
      {posts.map((post) => (
                 <div class="blogSection-container" key={post.slug.current}>
                 <div class="blogSection-box-wrapper"> 
                     <div class="blogSection-img-box">
                         <img src={post.mainImage.asset.url} alt={post.title} style={{width:'100%'}}/>
                     </div>
                     <div class="blogSection-content-box">
                         <h4>{post.title}</h4>
                         <Link to={`/blog/${post.slug.current}`} style={{color:'white',fontWeight:'600'}}>Read Article</Link>
                     </div>
                 </div>
             </div>
            ))} 
        

    </div>
  )
}

export default BlogSection
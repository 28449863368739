import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import './FooterNew.css'
// import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import EmailIcon from '@mui/icons-material/Email';
// import CallIcon from '@mui/icons-material/Call';
import { HashLink } from 'react-router-hash-link';
import Form from 'react-bootstrap/Form';
import {useRef, useState} from 'react';
import { Button, Row, Col } from 'react-bootstrap';

function Footer() { 

  const formRef = useRef(null)
  const scriptUrl = "https://script.google.com/macros/s/AKfycbyYXjMJo04KCQUdwAQuI4uDdNxjK560XNrwKp7XGE7-Y97TiwJjJRup9h8MIwoMjDlFgw/exec"
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) =>{
    console.log("clicked")
      e.preventDefault()
      setLoading(true)
      console.log(formRef.current);

      fetch(scriptUrl, {
      method: 'POST', 
      body: new FormData(formRef.current),
      

  }).then(res => {
          alert("SUCCESSFULLY SUBMITTED")
          setLoading(false)
      })
      .catch(err => console.log(err))
  }

  return (
    <div className='footer'> 
        <div className='footer-Newsletter'>
              <div className='footer-Newsletter-left'>
                  <div className='footer-Newsletter-left-box'>
                  <h2 style={{lineHeight:'1'}}>Get Access To Our Insight</h2>
                  <p>Stay in the loop! Sign up for our Newsletter</p>
                  </div> 
              </div> 
              <div className='footer-Newsletter-right'>
              <Form ref={formRef} onSubmit={handleSubmit} name="google-sheet">
              <Row>
                <Col>
                  <Form.Group  controlId="contactForm.ControlInput1">
                    <Form.Control type="email" name="Email" placeholder="Enter Your Mail..." style={{ width:'238px', borderRadius:'0px'}} />
                  </Form.Group>  
                 </Col>
                 <Col>
                  <Button variant="outline-secondary" type="submit" className='footer-Newsletter-right-submit' style={{color:'white',borderRadius:'0px', padding:'6px 16px'}} >Subscribe</Button>
                  </Col>
              </Row>    
                {/* <input type="email" placeholder="Enter Your Mail..." name="e-mail" required className='footer-Newsletter-right-mail'/> */}
                {/* <input type="submit" value="Subscribe" className='footer-Newsletter-right-submit'/> */}
              </Form>
              </div>
        </div> 
        <div className='footer__col__wrapper'>
          <div className='footer_col_left'>
                <div className='footer__col'>
                    <h4>Our Work</h4>
                        <li><NavLink to='/company' className="Navlink">About Amicus</NavLink></li>
                        <li><NavLink to='/company' className="Navlink">Our Team</NavLink></li>
                        <li><NavLink to='/report' className="Navlink">Reports</NavLink></li>
                        <li><NavLink to='/blog' className="Navlink">Blog</NavLink></li>
                        <li><NavLink to='/contact' className="Navlink">Resources</NavLink></li>
                        <li><NavLink to='/career' className="Navlink">Career</NavLink></li>
                </div>
                <div className='footer__col'>
                    <h4>Industries</h4>
                    <li><NavLink to='/logistics' className="Navlink">Logistics</NavLink></li>
                    <li><NavLink to='/d2c' className="Navlink">Ecommerce</NavLink></li>
                    <li><NavLink to='/retail' className="Navlink">Retail</NavLink></li>
                    <li><NavLink to='/manufacturing' className="Navlink">Manufacturing</NavLink></li>
                    <li><NavLink to='/hospitality' className="Navlink">Hospitality</NavLink></li>
                    <li><NavLink to='/edtech' className="Navlink">Edtech</NavLink></li>
                </div> 
          </div>  

          <div className='footer_col_right'>
              <div className='footer__col'>
                <h4>Our Services</h4>
                <li><HashLink smooth to='/services/business-transformation-consultants' className="Navlink">Business Transformation</HashLink></li>
                <li><HashLink smooth to='/services/sme-ipo-consultants' className="Navlink">SME IPO</HashLink></li>
                <li><HashLink smooth to='/services/business-diagnostics-and-strategic-business-consultant' className="Navlink">Business Diagnostic</HashLink></li>
                <li><HashLink smooth to='/services/startup-consulting-and-advisory-services' className="Navlink">Startup Advisory</HashLink></li>
                <li><HashLink smooth to='/services/virtual-cfo' className="Navlink">Virtual-CFO Services</HashLink></li>
                <li><HashLink smooth to='/services/esg-advisory' className="Navlink">ESG Advisory</HashLink></li>
              </div>
              <div className='footer__col'> 
                <h4>Contact Us</h4> 
                <li><a href="#" className="Navlink" style={{marginLeft:'5px'}}>Head Office - Mumbai </a></li>
                <li><a href="mailto:info@amicussllp.in" className="Navlink" style={{marginLeft:'5px'}}>business@amicusllp.com</a></li>
                <li><a href="tel:+91-9876543210" className="Navlink" style={{marginLeft:'15px'}}>+91-9820075360</a></li>
                    <div className='footer__lower__second_social_icons'>
                        <a href='https://www.linkedin.com/company/amicus-insolvency-turnaround-services-llp/' target='_blank' style={{color:'white'}}><LinkedInIcon style={{fontSize:'29px', marginRight:'0px 10px'}}/></a>
                        <a target='_blank' href='whatsapp://send?text=Thankyou for contacting Us!&phone=+919820075360' style={{color:'white'}}><WhatsAppIcon style={{fontSize:'29px',marginRight:'10px'}}/></a>
                        <a target='_blank' href='https://www.instagram.com/amicusgrowthadvisors/?igshid=YmMyMTA2M2Y%3D' style={{color:'white'}}><InstagramIcon style={{fontSize:'29px',marginRight:'10px'}}/></a>
                        <a target='_blank' href='https://www.facebook.com/AmicusGrowthAdvisors/?paipv=0&eav=AfbVy0HUisgOYqeVZnLZlWZnBJYfraFZdxg8bx7hTcgoveDWeUkxXNsm7dHWBR8YuDI&_rdr' style={{color:'white'}}><FacebookIcon style={{fontSize:'29px'}}/></a>

                    </div>
              </div>
          </div>
        </div>  
              <div className='footer__before__lower__area'>
                  <Link to='/'><h3 className='amicus-big-font'>amicus.</h3></Link>
              </div>
        
        <div className='footer__lower__area'>
        <div className='footer__lower__first'>
          <p>Copyright 2023 | Developed By <span style={{fontWeight:'600'}}><a href="https://mirakycloudmedia.com/" target='blank' className="Navlink">Miraky Cloud Media</a></span> </p>
         
        </div> 
        <div className='footer__lower__second'>
              <div className='footer__lower__second_content'>
              <p><NavLink to='/privacy-policy' className="Navlink">Privacy Policy</NavLink></p>
              <p><NavLink to='/terms' className="Navlink">Terms & Conditions</NavLink></p>
                {/* <p>|</p> */}
                <p><NavLink to='/contact' className="Navlink">Contact Us</NavLink></p>
              </div>
              
        </div>
        </div>
        
    </div>
  )
}

export default Footer
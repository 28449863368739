import React from 'react'
import IndustryTop from './IndustryTop'
import IndustryExpertise from './IndustryExpertise'
import PageTop from '../Assets/pageTop/PageTop'
import {Helmet} from "react-helmet";

function Manufacturing() {
  return (
    <div>

        <Helmet>
            <meta charSet="utf-8" />
            <title>Business Consultant | Business Advisor - Amicus Growth Advisors</title>
            <meta name="keyword" content="business consultant, business consultant in india, business advisor, strategy consultant, go to market strategist" />
            <meta name="description" content="Amicus Growth Advisors, India's leading business consultancy, specializes in strategy, market entry, and expert advising to propel your business forward." />
            <link rel="canonical" href="https://www.amicusllp.com/manufacturing" />
        </Helmet>

      <PageTop
        pageName="INDUSTRY PAGE" 
        headingFirstHalf="DIVERSE "
        headingSecondHalf="SECTORS"
        belowHeading='Embrace the dynamism of industries; fuel your enterprise potential.'
        paragraph="As a business leader in a dynamic industry, challenges are part and parcel of your journey. With a multitude of tasks and scarce resources, it's crucial to stay agile. Our firm is here to guide you through industry-specific complexities, help unearth hidden opportunities, offer a fresh industry perspective, and equip you for the next big industry breakthrough."
        />
        <IndustryTop 
        topHeading = 'Manufacturing'
        topPara1 = 'The manufacturing industry, a cornerstone of economic development, faces unique challenges in operational efficiency, innovation, and sustainability. Our services equip manufacturers with strategies for business transformation, optimized financial management, and effective M&A planning.'
        topPara2 = 'Rely on us to strengthen your competitive edge, assisting your manufacturing enterprise to thrive in a technologically advanced and globally connected landscape.'
        img = './images/manufacturing.jpg'
        />
       <IndustryExpertise 
      h1='Business Transformation'
      desc1='Manufacturing'
      h2='Board Advisory'
      desc2='Utilize our expert guidance to craft insightful roadmaps and effective corporate governance strategies.'
      h3='Virtual CFO Service'
      desc3='Optimize financial performance with our comprehensive, remote CFO services, tailored to your needs.'
      h4='Mergers and acquisitions'
      desc4='Seamlessly navigate M&As with our strategies, maximizing value and ensuring smooth transitions.'
      h5='Debt & Equity Funding'
      desc5=' Leverage our expertise for optimal funding solutions, striking a balance between debt and equity.'
      
      />
    </div>
  ) 
}

export default Manufacturing
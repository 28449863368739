import React from 'react';
import './Career.scss'
import { Col, Container, Row } from 'react-bootstrap';
import PageTop from '../Assets/pageTop/PageTop';
import {Helmet} from "react-helmet";

function Career() {
  return (
    <div className='career'>
     
     <Helmet>
            <meta charSet="utf-8" />
            <title>Join Amicus Growth Advisors: Shape Your Career Here</title>
            <meta name="keyword" content="Business Consultant, business transformation, sme ipo consultants, startup consultant, business diagnostics service, strategic business consultant" />
            <meta name="description" content="Embark on a fulfilling career with Amicus Growth Advisors. Explore opportunities in business consulting and be a part of a team that shapes industry futures." />
            <link rel="canonical" href="https://www.amicusllp.com/career" />
        </Helmet>

<PageTop
        pageName="CAREER"
        headingFirstHalf="JOIN"
        headingSecondHalf="OUR TEAM"
        paragraph="As an entrepreneur or investor, you have a lot on your plate. With numerous responsibilities and limited resources. That's where Amicus come in."
        />

      {/* Feature section */}
      <Container className='career-feature-section' >
        <Row className='align-items-center career-feature-section-row'>
          <Col xs={12} sm={12} md={12} lg={6} className='career-feature-section-image'>
            <img src="./career0.jpg" alt="Career-Growth" className='img-fluid' />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <div>
              <ul className='career-features-list'>
                <li>Growth and Development</li>
                <li>Collaborative Culture</li>
                <li>Innovation and Creativity</li>
                <li>Impactful Work</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className='career-box-container'>
          <div className="career-section-title" style={{paddingLeft:"35px"}}>
            <h3 className='mb-3' >OPEN POSITIONS</h3>
            <p>We are eagerly looking forward to extending our invitation to you at the pond, now backed by the full force of Amicus Invent.</p>
          </div>

          <Row>
            <Col xs={12} sm={12} md={12} lg={6}>
              <div className="position-box">
                <h2>Career with Us </h2>
                <Row className='align-items-end'>
                  <Col><h6>We are Open to</h6></Col>
                  <Col className='text-end'><img src="./amicus-icon.svg" alt="Amicus Icon" /></Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6}>
              <div className="position-box">
                <h2>Boundless Opportunities with our Business ventures and partners </h2>
                <Row className='align-items-end'>
                  <Col><h6>See Open Roles </h6></Col>
                  <Col className='text-end'><img src="./amicus-icon.svg" alt="Amicus Icon" /></Col>
                </Row>
              </div>
            </Col>
          </Row>
      </Container>

     
    </div>
  )
}

export default Career